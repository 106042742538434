import React, { useState } from 'react';
import { Box, Typography, Card, CardContent, Collapse, Grid, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const windowTypes = [
    {
        title: 'Однокамерные стеклопакеты',
        description: 'Однокамерные стеклопакеты состоят из двух стекол с воздушным пространством между ними...',
        image: 'https://via.placeholder.com/400x200',
    },
    {
        title: 'Двухкамерные стеклопакеты',
        description: 'Двухкамерные стеклопакеты состоят из трех стекол с двумя воздушными камерами...',
        image: 'https://via.placeholder.com/400x200',
    },
];

const theme = createTheme({
    typography: {
        h5: {
            fontSize: '24px',
            '@media (max-width:600px)': {
                fontSize: '20px',
            },
        },
        h6: {
            fontSize: '20px',
            '@media (max-width:600px)': {
                fontSize: '18px',
            },
        },
        subtitle1: {
            fontSize: '18px',
            '@media (max-width:600px)': {
                fontSize: '16px',
            },
        },
        body1: {
            fontSize: '16px',
            '@media (max-width:600px)': {
                fontSize: '14px',
            },
        },
        body2: {
            fontSize: '14px',
            '@media (max-width:600px)': {
                fontSize: '12px',
            },
        },
    },
});

const WarmIsolation = () => {
    const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

    const handleExpandClick = (index: number) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ padding: 3 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', textAlign: 'left', marginBottom: 1 }}>
                    Уровень теплоизоляции
                </Typography>
                <Grid container spacing={2}>
                    {windowTypes.map((window, index) => (
                        <Grid item xs={12} md={6} key={window.title}>
                            <Card
                                sx={{
                                    borderRadius: 4,
                                    display: 'flex',
                                    padding: 2,
                                    flexDirection: { xs: 'column', md: 'row' }
                                }}
                            >
                                <CardContent sx={{ flex: 1, width: { xs: '100%', md: '60%' } }}>
                                    <Stack>
                                        <Typography gutterBottom variant="h6" component="div" sx={{ fontFamily: 'Unbounded' }}>
                                            {window.title}
                                        </Typography>
                                        <Stack
                                            sx={{ cursor: 'pointer', width: 'max-content', alignItems: 'center' }}
                                            direction="row"
                                            onClick={() => handleExpandClick(index)}
                                        >
                                            <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'Unbounded' }}>
                                                {expandedIndex === index ? 'Свернуть' : 'Подробнее'}
                                            </Typography>
                                        </Stack>
                                        <Collapse in={expandedIndex === index} timeout="auto" unmountOnExit>
                                            <Box sx={{ paddingTop: 2 }}>
                                                <Typography variant="body2" sx={{ fontFamily: 'Unbounded', textAlign: 'justify', wordBreak: 'break-word' }}>
                                                    {window.description}
                                                </Typography>
                                            </Box>
                                        </Collapse>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </ThemeProvider>
    );
};

export default WarmIsolation;
