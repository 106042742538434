import React, { useState } from 'react';
import Component1 from './Component1.jsx';
import Component2 from './Component2.jsx';
import Component3 from './Component3.jsx';
import Component4 from './Component4.jsx';
import Component5 from './Component5.jsx';
import Component6 from './Component6.jsx';
import Component7 from './Component7.jsx';
import Component8 from './Component8.jsx';
import Component9 from './Component9.jsx';
import Component10 from './Component10.jsx';
import Component11 from './Component11.jsx';
import Component12 from './Component12.jsx';
import Component13 from './Component13.jsx';
import Componentex from './Componentex.jsx';
import UpperMenu from '../UpperMenu.jsx';
import ContactForm from '../../contact-form/ContactForm.jsx';
import messageBtn from '../../contact-form/Images/message.svg';
import '../../../css/mainPage/mainPage.css';
import Component14 from "./Component14";

// Импортируйте остальные компоненты

const MainPage = () => {
  const [isContactFormVisible, setContactFormVisible] = useState(false);

  const handleToggleContactForm = () => {
    setContactFormVisible(!isContactFormVisible);
  };

  const handleModalClose = () => {
    setContactFormVisible(false);
  };

  return (
    <div className="main-page">
      <UpperMenu />
      <Component1 handleToggleContactForm={handleToggleContactForm} />
      <Component2 />
      <Component4 handleToggleContactForm={handleToggleContactForm} />
      <Component5 />
      <Component6 />
        <Component14/>
      {/*<Component7 handleToggleContactForm={handleToggleContactForm} />*/}
      <Component9 />
      <Component10 />
      <Component11 />
      <Component12 />
      <Component13 handleToggleContactForm={handleToggleContactForm} />
      <img
        src={messageBtn}
        alt=""
        className="main-page__message-btn"
        onClick={handleToggleContactForm}
      />
      {isContactFormVisible && (
        <div className="modal-overlay">
          <ContactForm onClose={handleModalClose} />
        </div>
      )}
    </div>
  );
};

export default MainPage;
