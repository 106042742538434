import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './ONas.css';
import bg from './images/bg.svg';
import woman from './images/woman.svg';
import clocks from './images/clocks.svg';
import docs from './images/docs.svg';
import UpperMenu from '../../../components/Elements/UpperMenu.jsx';
import Component13 from '../../../components/Elements/mainPage/Component13.jsx';
import Sertifikaty from './Sertifikaty.jsx';

const ONas = () => {
  const menuArr = ['О компании', 'История', 'Лицензии', 'Партнерам', 'Поставщикам', 'Документы'];

  const [activePage, setActivePage] = useState('');

  const [isDesktop, setIsDesktop] = useState(window.innerWidth);
  const handleResize = () => {
    setIsDesktop(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Пластиковые Окна Окна ПВХ</title>
        <meta
          name="description"
          content="Пластиковые окна

          Пластиковые окна Пластиковые окна — это современное и практичное решение для вашего дома или квартиры. Они обладают множеством преимуществ, которые делают их лучшим выбором для остекления любых помещений. Вот некоторые из них:
          
          - Изделия из ПВХ имеют высокую теплоизоляцию и энергоэффективность. Они не пропускают холод и сквозняки, сохраняют тепло внутри и снижают расходы на отопление. Такие окна также защищают от перегрева в жаркое время года, благодаря специальным стеклопакетам с солнцезащитой.
          - Эти конструкции обеспечивают хорошую защиту от шума. Они изолируют внешние звуки и создают тихую и уютную атмосферу в доме. Они также устойчивы к взлому и повреждениям, благодаря качественной фурнитуре и противовзломным системам.
          - Оконные рамы из пластика легко устанавливаются и обслуживаются. Они не нуждаются в покраске или ремонте, не коррозируют и не деформируются. Они легко моются и не теряют свой цвет и блеск со временем. Они также имеют долгий срок службы — до 50 лет и более.
          - Оконные блоки из пластика имеют разнообразный дизайн и подходят для любого интерьера. Вы можете выбрать их любого вида, размера и формы: арочные, эркерные, панорамные, раздвижные, подъемно-раздвижные и другие. Вы также можете выбрать их любого цвета: белые, черные, цветные, ламинированные под дерево или камень.
          - Они подходят для остекления любых помещений: квартир, домов, балконов, лоджий, террас, веранд, детских, коттеджей и других. Они создают комфорт и улучшают микроклимат в помещениях. Они обеспечивают свежий воздух и оптимальную влажность, благодаря системам вентиляции и конденсата. Они также предотвращают появление плесени и грибка, которые могут нанести вред здоровью.
          - Они экологичны и безопасны для окружающей среды. Они не содержат вредных веществ и не выделяют токсичных газов. Они также экономят электроэнергию и снижают выбросы углекислого газа. Они изготавливаются из перерабатываемых материалов и могут быть утилизированы после окончания срока службы.
          Как вы видите, пластиковые окна — это идеальный вариант для вашего дома или квартиры. Они сочетают в себе качество, функциональность, эстетику и экономию. "
        />
        <meta property="og:title" content="Пластиковые Окна Окна ПВХ" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://ok-schtern.ru/" />
        <meta property="og:image" content="URL_ИЗОБРАЖЕНИЯ" />
        <meta
          property="og:description"
          content="Пластиковые окна — это современное и практичное решение для вашего дома или квартиры."
        />
        <meta property="og:site_name" content="SCHTERN" />
        <meta property="og:locale" content="ru_RU" />
      </Helmet>
      <UpperMenu />

      {isDesktop > 480 ? (
        <>
          <div className="oNas">
            <div className="oNas__img">
              <img src={bg} alt="" />
            </div>
            {/* <div className="oNas__nav">
              {menuArr.map((item) => (
                <p
                  onClick={(event) => setActivePage(event.target.textContent)}
                  className={activePage === item ? 'oNas__nav--activeTitle' : 'oNas__nav--title'}>
                  {item}
                </p>
              ))}
            </div> */}
          </div>
        </>
      ) : (
        <>
          <div className="oNas">
            <div className="oNas__img--clocks">
              <img src={clocks} alt="" />
            </div>
            <div className="oNas__img--docs">
              <img src={docs} alt="" />
            </div>
            <div className="oNas__img--woman">
              <img src={woman} alt="" />
            </div>
            {/* <div className="oNas__nav">
              {menuArr.map((item) => (
                <p
                  onClick={(event) => setActivePage(event.target.textContent)}
                  className={activePage === item ? 'oNas__nav--activeTitle' : 'oNas__nav--title'}>
                  {item}
                </p>
              ))}
            </div> */}
          </div>
        </>
      )}

      <Sertifikaty />

      <Component13 />
    </>
  );
};
export default ONas;
