import React, { useState, useEffect } from 'react';
import './partneram.css';
import truck from './images/truck.svg';
import woman from './images/woman.svg';
import clocks from './images/clocks.svg';
import frame from './images/frame.svg';
import bike from './images/bike.svg';
import books from './images/books.svg';
import laptop from './images/laptop.svg';

const PartneramBody = () => {
  const menuArr = ['О компании', 'История', 'Лицензии', 'Партнерам', 'Поставщикам', 'Документы'];

  const [activePage, setActivePage] = useState('');

  const [isDesktop, setIsDesktop] = useState(window.innerWidth);
  const handleResize = () => {
    setIsDesktop(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {isDesktop > 480 ? (
        <>
          <div className="partneram__info-container">
            <div className="partneram__info-container--top">
              <h2>Партнерам</h2>
              <div className="partneram__info-container--top-description">
                <div className="partneram__info-container--top-description-text">
                  Применение инновационных оконных технологий, четко выстроенная стратегия развития
                  компании и прочные позиции на рынке позволяют оконной компании SCHTERN активно
                  осваивать российский рынок светопрозрачных конструкций. Мы высоко ценим ваше
                  внимание и выражаем вам свою признательность за намерение стать нашим партнером.
                  Наши крупные партнеры получают возможность быстро и эффективно развиваться
                  благодаря активной поддержке. Мы хотим, чтобы и ваша компания открыла для себя
                  новые возможности.
                </div>
                <img src={truck} alt="" />
              </div>
            </div>
            <div className="partneram__info-container--grids">
              <h2>Преимущества работы с SCHTERN</h2>
              <div className="partneram__info-container--grids-1">
                <img src={woman} alt="" style={{ width: '21vw', marginRight: '8vw' }} />
                <div className="partneram__info-container--grids-container">
                  <div className="partneram__info-container--grid">
                    Персональный менеджер. Мы индивидуально подходим к каждому партнеру. Партнер
                    самостоятельно определяет степень и форму нашего участия в том или ином проекте.
                  </div>
                  <div className="partneram__info-container--grid">
                    Доступность и оперативность получения информации. Партнеры первыми получают
                    информацию обо всех изменениях и могут безболезненно оптимизировать свои
                    бизнес-процессы. Отслеживание состояния заказов происходит в on-line режиме.
                  </div>
                  <div className="partneram__info-container--grid">
                    Постоянное расширение ассортимента предлагаемой продукции за счет эксклюзивных
                    продуктов, новейшего оборудования с применением инновационных технологий.
                  </div>
                  <div className="partneram__info-container--grid">
                    Высокая конкурентоспособность предлагаемых решений, их функциональность и
                    технические особенности обеспечивают партнеру неоспоримые преимущества.
                  </div>
                  <div
                    className="partneram__info-container--grid"
                    style={{ display: 'flex', flexDirection: 'column' }}>
                    <img
                      src={clocks}
                      alt=""
                      style={{ width: '7vw', marginLeft: '9vw', marginBottom: '5.5vw' }}
                    />
                    <img
                      src={frame}
                      alt=""
                      style={{
                        width: '4vw',
                        position: 'absolute',
                        top: '9vw',
                        left: '7vw'
                      }}
                    />
                    <img
                      src={frame}
                      alt=""
                      style={{
                        width: '4vw',
                        position: 'absolute',
                        top: '6vw',
                        left: '2vw'
                      }}
                    />
                    Строгое соблюдение сроков изготовления изделий.
                  </div>
                  <div
                    className="partneram__info-container--grid"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-around'
                    }}>
                    Бесплатная доставка на расстояние до 550 км.
                    <img src={bike} alt="" style={{ width: '15vw', marginRight: '0vw' }} />
                  </div>
                  <div className="partneram__info-container--grid">
                    Рекламная поддержка. Мы совместно с партнером планируем и проводим различные
                    маркетинговые кампании и акции. Мы обеспечиваем наших партнеров всеми
                    необходимыми материалами, проводим совместные семинары, участвуем в выставках и
                    конференциях.
                  </div>
                  <div
                    className="partneram__info-container--grid"
                    style={{ flexDirection: 'column', justifyContent: 'space-around' }}>
                    Обучение персонала.
                    <img src={books} alt="" style={{ width: '14vw' }} />
                  </div>
                  <div
                    className="partneram__info-container--grid"
                    style={{ flexDirection: 'column', justifyContent: 'space-around' }}>
                    Поддержка Сервисного центра.
                    <img src={laptop} alt="" style={{ width: '15vw' }} />
                  </div>
                </div>
              </div>
            </div>
            <div className="partneram__info-container--how-to">
              <h2>Стать партнером</h2>
              <div className="partneram__info-container--how-to-steps">
                <div className="partneram__info-container--how-to-step1">
                  <p className="partneram__info-container--how-to-step1_1">1</p>
                  <p className="partneram__info-container--how-to-step1_2">
                    Статус юридического лица или индивидуального предпринимателя
                  </p>
                </div>
                <div className="partneram__info-container--how-to-step2">
                  <p className="partneram__info-container--how-to-step2_1">2</p>
                  <p className="partneram__info-container--how-to-step2_2">
                    Собственные оборотные средства
                  </p>
                </div>
                <div className="partneram__info-container--how-to-step3">
                  <p className="partneram__info-container--how-to-step3_1">3</p>
                  <p className="partneram__info-container--how-to-step3_2">
                    Соблюдение корпоративных стандартов и регламентов SCHTERN
                  </p>
                </div>
                <div className="partneram__info-container--how-to-step4">
                  <p className="partneram__info-container--how-to-step4_1">4</p>
                  <p className="partneram__info-container--how-to-step4_2"> Прохождение обучения</p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="mob_partneram__header--decription">
            <h2>Партнерам</h2>
            <div className="mob_partneram__header--description-text">
              Применение инновационных оконных технологий, четко выстроенная стратегия развития
              компании и прочные позиции на рынке позволяют оконной компании SCHTERN активно
              осваивать российский рынок светопрозрачных конструкций. Мы высоко ценим ваше внимание
              и выражаем вам свою признательность за намерение стать нашим партнером. Наши крупные
              партнеры получают возможность быстро и эффективно развиваться благодаря активной
              поддержке. Мы хотим, чтобы и ваша компания открыла для себя новые возможности.
            </div>
            <div className="mob_partneram__header--description-img">
              <img src={truck} alt="" />
            </div>
          </div>
          <div className="mob_partneram__header--cards">
            <h2>Преимущества работы с SCHTERN</h2>
            <div className="mob_partneram__header--cards1">
              Персональный менеджер. Мы индивидуально подходим к каждому партнеру. Партнер
              самостоятельно определяет степень и форму нашего участия в том или ином проекте.
            </div>
            <div className="mob_partneram__header--cards2">
              Доступность и оперативность получения информации. Партнеры первыми получают информацию
              обо всех изменениях и могут безболезненно оптимизировать свои бизнес-процессы.
              Отслеживание состояния заказов происходит в on-line режиме.
            </div>
            <div className="mob_partneram__header--cards3">
              Постоянное расширение ассортимента предлагаемой продукции за счет эксклюзивных
              продуктов, новейшего оборудования с применением инновационных технологий.
            </div>
            <div className="mob_partneram__header--cards4">
              {' '}
              Высокая конкурентоспособность предлагаемых решений, их функциональность и технические
              особенности обеспечивают партнеру неоспоримые преимущества.
            </div>
            <div className="mob_partneram__header--cards5">
              {' '}
              <img
                src={clocks}
                alt=""
                style={{ position: 'absolute', top: '7vw', left: '60vw', width: '15vw' }}
              />
              <img
                src={frame}
                alt=""
                style={{ position: 'absolute', top: '8vw', left: '40vw', width: '10vw' }}
              />
              <img
                src={frame}
                alt=""
                style={{ position: 'absolute', top: '12vw', left: '28vw', width: '10vw' }}
              />
              Строгое соблюдение сроков изготовления изделий.
            </div>
            <div className="mob_partneram__header--cards6">
              {' '}
              <img src={bike} alt="" style={{ marginBottom: '5vw', width: '40vw' }} />
              Бесплатная доставка на расстояние до 550 км.
            </div>
            <div className="mob_partneram__header--cards7">
              Рекламная поддержка. Мы совместно с партнером планируем и проводим различные
              маркетинговые кампании и акции. Мы обеспечиваем наших партнеров всеми необходимыми
              материалами, проводим совместные семинары, участвуем в выставках и конференциях.
            </div>
            <div className="mob_partneram__header--cards8">
              <img src={books} alt="" style={{ marginBottom: '5vw', width: '40vw' }} />
              Обучение персонала.
            </div>
            <div className="mob_partneram__header--cards9">
              <img src={laptop} alt="" style={{ marginBottom: '5vw', width: '40vw ' }} />
              Поддержка Сервисного центра.
            </div>
            <div className="mob_partneram__info-container--how-to-steps">
              <div style={{ fontSize: '5vw', color: '#161E54', marginBottom: '5vw' }}>
                Как стать Партнером
              </div>
              <div className="mob_partneram__info-container--how-to-step1">
                <div className="mob_partneram__info-container--how-to-step1_1">1</div>
                <div className="mob_partneram__info-container--how-to-step1_2">
                  Статус юридического лица или индивидуального предпринимателя
                </div>
              </div>
              <div className="mob_partneram__info-container--how-to-step2">
                <div className="mob_partneram__info-container--how-to-step2_1">2</div>
                <div className="mob_partneram__info-container--how-to-step2_2">
                  Собственные оборотные средства
                </div>
              </div>
              <div className="mob_partneram__info-container--how-to-step3">
                <div className="mob_partneram__info-container--how-to-step3_1">3</div>
                <div className="mob_partneram__info-container--how-to-step3_2">
                  Соблюдение корпоративных стандартов и регламентов SCHTERN
                </div>
              </div>
              <div className="mob_partneram__info-container--how-to-step4">
                <div className="mob_partneram__info-container--how-to-step4_1">4</div>
                <div className="mob_partneram__info-container--how-to-step4_2">
                  Прохождение обучения
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default PartneramBody;
