import React from 'react';

const GdeKupit = () => {

    return (
        <>

       <p>
        Где купить
       </p>

        </>
    )
}

export default GdeKupit;