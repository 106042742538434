import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './Sertifikaty.css';
import pdf from './images/pdf.svg';
import doc from './images/doc.svg';
import xls from './images/xls.svg';
import download from './images/download.svg';

const ONas = () => {
  const docsArr = [
    {
      name: 'Сводная ведомость результатов проведения специальной оценки условий труда ООО "Оконные Изделия"  ',
      img: pdf,
      size: '225Kb',
      filename: 'spec-okon.pdf'
    },
    {
      name: 'Сводная ведомость результатов проведения специальной оценки условий труда ООО "ШТЕРН-ГРУПП"',
      img: pdf,
      size: '164Kb',
      filename: 'spec-schtern.pdf'
    },
    {
      name: 'Сертификат соответствия',
      img: pdf,
      size: '1054Kb',
      filename: 'Sertifikat_sootvetstviya.pdf'
    }
  ];

  const handleDownload = (filename) => {
    fetch(`https://schtern-mail.ru/download/${filename}`)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => console.error('Error downloading file:', error));
  };

  const [isDesktop, setIsDesktop] = useState(window.innerWidth);
  const handleResize = () => {
    setIsDesktop(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Пластиковые Окна Окна ПВХ</title>
        <meta
          name="description"
          content="Пластиковые окна

          Пластиковые окна Пластиковые окна — это современное и практичное решение для вашего дома или квартиры. Они обладают множеством преимуществ, которые делают их лучшим выбором для остекления любых помещений. Вот некоторые из них:
          
          - Изделия из ПВХ имеют высокую теплоизоляцию и энергоэффективность. Они не пропускают холод и сквозняки, сохраняют тепло внутри и снижают расходы на отопление. Такие окна также защищают от перегрева в жаркое время года, благодаря специальным стеклопакетам с солнцезащитой.
          - Эти конструкции обеспечивают хорошую защиту от шума. Они изолируют внешние звуки и создают тихую и уютную атмосферу в доме. Они также устойчивы к взлому и повреждениям, благодаря качественной фурнитуре и противовзломным системам.
          - Оконные рамы из пластика легко устанавливаются и обслуживаются. Они не нуждаются в покраске или ремонте, не коррозируют и не деформируются. Они легко моются и не теряют свой цвет и блеск со временем. Они также имеют долгий срок службы — до 50 лет и более.
          - Оконные блоки из пластика имеют разнообразный дизайн и подходят для любого интерьера. Вы можете выбрать их любого вида, размера и формы: арочные, эркерные, панорамные, раздвижные, подъемно-раздвижные и другие. Вы также можете выбрать их любого цвета: белые, черные, цветные, ламинированные под дерево или камень.
          - Они подходят для остекления любых помещений: квартир, домов, балконов, лоджий, террас, веранд, детских, коттеджей и других. Они создают комфорт и улучшают микроклимат в помещениях. Они обеспечивают свежий воздух и оптимальную влажность, благодаря системам вентиляции и конденсата. Они также предотвращают появление плесени и грибка, которые могут нанести вред здоровью.
          - Они экологичны и безопасны для окружающей среды. Они не содержат вредных веществ и не выделяют токсичных газов. Они также экономят электроэнергию и снижают выбросы углекислого газа. Они изготавливаются из перерабатываемых материалов и могут быть утилизированы после окончания срока службы.
          Как вы видите, пластиковые окна — это идеальный вариант для вашего дома или квартиры. Они сочетают в себе качество, функциональность, эстетику и экономию. "
        />
        <meta property="og:title" content="Пластиковые Окна Окна ПВХ" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://ok-schtern.ru/" />
        <meta property="og:image" content="URL_ИЗОБРАЖЕНИЯ" />
        <meta
          property="og:description"
          content="Пластиковые окна — это современное и практичное решение для вашего дома или квартиры."
        />
        <meta property="og:site_name" content="SCHTERN" />
        <meta property="og:locale" content="ru_RU" />
      </Helmet>

      {isDesktop > 480 ? (
        <div className="sertifikaty">
          <h1></h1>
          <h2></h2>
          <div className="sertifikaty__sheet">
            {docsArr.map((doc) => (
              <div className="sertifikaty__sheet--item">
                <img src={doc.img} alt="doc-type" className="sertifikaty__sheet-_item_img" />

                <div className="sertifikaty__sheet--item--inner">
                  <div className="sertifikaty__sheet-_item_name">{doc.name}</div>
                  <div className="sertifikaty__sheet-_item_size">{doc.size}</div>
                </div>
                <img
                  className="sertifikaty__sheet-_item_load"
                  src={download}
                  alt=""
                  onClick={() => handleDownload(doc.filename)}
                />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="sertifikaty">
          <h1></h1>
          <h2></h2>
          <div className="sertifikaty__sheet">
            {docsArr.map((doc) => (
              <div className="sertifikaty__sheet--item">
                <div className="sertifikaty__sheet--item--inner">
                  <img src={doc.img} alt="doc-type" className="sertifikaty__sheet-_item_img" />
                  <div className="sertifikaty__sheet-_item_name_mob">{doc.name}</div>
                  <div className="sertifikaty__sheet-_item_size">{doc.size}</div>
                </div>
                <img
                  src={download}
                  alt=""
                  className="sertifikaty__sheet-_item_load_mob"
                  onClick={() => handleDownload(doc.filename)}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
export default ONas;
