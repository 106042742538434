/* eslint-disable react-hooks/exhaustive-deps */
import window11 from './images/po-11.svg';
import window21 from './images/po-21.svg';
import window31 from './images/po-31.svg';
import window41 from './images/po-41.svg';
import window51 from './images/po-51.svg';
import window61 from './images/po-61.svg';
import window12 from './images/po-12.svg';
import window22 from './images/po-22.svg';
import window32 from './images/po-32.svg';
import window42 from './images/po-42.svg';
import window52 from './images/po-52.svg';
import window62 from './images/po-62.svg';
import bg from './images/bg_mob.svg';
import mainWindow from './images/main-window.svg';

import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './Odnokamernye_sp_mob.css';
import UpperMenu from '../../../Elements/UpperMenu.jsx';
import Component13 from '../../../Elements/mainPage/Component13.jsx';
const Odnokamernye_sp_mob = () => {
  const [img1, setImg1] = useState({ img: window11, isHover: false });
  const [img2, setImg2] = useState({ img: window21, isHover: false });
  const [img3, setImg3] = useState({ img: window31, isHover: false });
  const [img4, setImg4] = useState({ img: window41, isHover: false });
  const [img5, setImg5] = useState({ img: window51, isHover: false });
  const [img6, setImg6] = useState({ img: window61, isHover: false });

  return (
    <>
      <Helmet>
        <title>Однокамерные стеклопакеты</title>
        <meta
          name="description"
          content="Однокамерные стеклопакеты

          Однокамерные стеклопакеты представляют собой конструкцию, состоящую из двух стекол, между которыми находится одна воздушная или газовая камера. Эти стеклопакеты широко используются в различных типах оконных систем и предлагают ряд преимуществ, а также имеют определенные ограничения в использовании.
          
          **Преимущества однокамерных стеклопакетов:**
          
          Однокамерные стеклопакеты имеют малый вес конструкции, что снижает нагрузку на оконные рамы и фурнитуру, увеличивая срок их службы.
          
          Однокамерные стеклопакеты имеют высокий коэффициент светопропускания. Они пропускают больше света по сравнению с двухкамерными, что делает их идеальным выбором для помещений, нуждающихся в максимальном естественном освещении.
          
          Доступная цена. Простота изготовления и монтажа однокамерных стеклопакетов делает их более доступными по сравнению с более сложными многослойными конструкциями.
          
          
          К недостаткам однокамерных стеклопакетов можно отнести:
          Высокий коэффициент теплопередачи - это означает, что через однокамерные стеклопакеты происходит больше теплопотерь, что может быть неэффективно в холодном климате без дополнительных мер по улучшению теплоизоляции.
          Однокамерные стеклопакеты менее эффективны в защите от уличного шума по сравнению с двухкамерными.
          
          Однокамерные стеклопакет оптимальны в регионах с мягким климатом, где среднезимние температуры не опускаются ниже -8°C.
          Для остекления балконов и лоджий, используемых в качестве хранилища или не требующих высокой теплоизоляции.
          В помещениях с дополнительной защитой от холода, например, если окно выходит на остекленную лоджию.
          
          Однокамерные стеклопакеты моно использовать для офисных зданий и производственных помещений, где требования к тепло- и звукоизоляции могут быть ниже, чем в жилых домах.
          На дачах и в загородных домах летнего проживания, где не требуется высокая защита от холода.
          
          Важно отметить, что при выборе стеклопакетов для своего дома или квартиры следует учитывать не только климатические условия и местоположение, но и индивидуальные потребности в тепло- и звукоизоляции. В некоторых случаях может быть целесообразно рассмотреть возможность установки двухкамерных стеклопакетов, которые обеспечивают лучшую тепло- и звукоизоляцию по сравнению с однокамерными, несмотря на их более высокую стоимость."
        />

        <meta property="og:title" content="Пластиковые Окна Окна ПВХ" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://ok-schtern.ru/" />
        <meta property="og:image" content="URL_ИЗОБРАЖЕНИЯ" />
        <meta
          property="og:description"
          content="Пластиковые окна — это современное и практичное решение для вашего дома или квартиры."
        />
        <meta property="og:site_name" content="SCHTERN" />
        <meta property="og:locale" content="ru_RU" />
      </Helmet>
      <UpperMenu />
      <div className="mob_odnokaernye-sp">
        <div className="mob_odnokaernye-sp__top-image">
          <img src={mainWindow} alt="main-window" />
        </div>
        <div className="mob_odnokaernye-sp__title">
          <h2>
            Однокамерные стеклопакеты представляют собой конструкцию, состоящую из двух стекол,
            между которыми находится одна воздушная или газовая камера. Эти стеклопакеты широко
            используются в различных типах оконных систем и предлагают ряд преимуществ, а также
            имеют определенные ограничения в использовании.
          </h2>
        </div>
        <div className="mob_odnokaernye-sp__more-info">Нажмите на окна, чтобы узнать подробнее</div>
        <div className="mob_odnokaernye-sp__window1">
          <img
            src={img1.img}
            alt="window11"
            onMouseEnter={() => setImg1({ img: window12, isHover: true })}
            onMouseLeave={() => setImg1({ img: window11, isHover: false })}
          />
          <div
            className={
              img1.isHover
                ? 'mob_odnokaernye-sp__title-card1--visible'
                : 'mob_odnokaernye-sp__title-card1'
            }
            onMouseEnter={() => setImg1({ img: window12, isHover: true })}
            onMouseLeave={() => setImg1({ img: window11, isHover: false })}>
            <p>
              {' '}
              Однокамерные стеклопакеты имеют малый вес конструкции, что снижает нагрузку на оконные
              рамы и фурнитуру, увеличивая срок их службы.
            </p>
          </div>
        </div>
        <div className="mob_odnokaernye-sp__window2">
          <img
            src={img2.img}
            alt="window21"
            onMouseEnter={() => setImg2({ img: window22, isHover: true })}
            onMouseLeave={() => setImg2({ img: window21, isHover: false })}
          />
          <div
            className={
              img2.isHover
                ? 'mob_odnokaernye-sp__title-card2--visible'
                : 'mob_odnokaernye-sp__title-card2'
            }
            onMouseEnter={() => setImg2({ img: window22, isHover: true })}
            onMouseLeave={() => setImg2({ img: window21, isHover: false })}>
            <p>
              Однокамерные стеклопакеты имеют высокий коэффициент светопропускания. Они пропускают
              больше света по сравнению с двухкамерными, что делает их идеальным выбором для
              помещений, нуждающихся в максимальном естественном освещении.
            </p>
          </div>
        </div>
        <div className="mob_odnokaernye-sp__window3">
          <img
            src={img3.img}
            alt="window31"
            onMouseEnter={() => setImg3({ img: window32, isHover: true })}
            onMouseLeave={() => setImg3({ img: window31, isHover: false })}
          />
          <div
            className={
              img3.isHover
                ? 'mob_odnokaernye-sp__title-card3--visible'
                : 'mob_odnokaernye-sp__title-card3'
            }
            onMouseEnter={() => setImg3({ img: window32, isHover: true })}
            onMouseLeave={() => setImg3({ img: window31, isHover: false })}>
            <p>
              Доступная цена. Простота изготовления и монтажа однокамерных стеклопакетов делает их
              более доступными по сравнению с более сложными многослойными конструкциями.
            </p>
          </div>
        </div>

        <div className="mob_odnokaernye-sp__window4">
          <img
            src={img4.img}
            alt="window41"
            onMouseEnter={() => setImg4({ img: window42, isHover: true })}
            onMouseLeave={() => setImg4({ img: window41, isHover: false })}
          />
          <div
            className={
              img4.isHover
                ? 'mob_odnokaernye-sp__title-card4--visible'
                : 'mob_odnokaernye-sp__title-card4'
            }
            onMouseEnter={() => setImg4({ img: window42, isHover: true })}
            onMouseLeave={() => setImg4({ img: window41, isHover: false })}>
            <p>
              К недостаткам однокамерных стеклопакетов можно отнести: <br />
              Высокий коэффициент теплопередачи - это означает, что через однокамерные стеклопакеты
              происходит больше теплопотерь, что может быть неэффективно в холодном климате без
              дополнительных мер по улучшению теплоизоляции. <br />
              Однокамерные стеклопакеты менее эффективны в защите от уличного шума по сравнению с
              двухкамерными.
            </p>
          </div>
        </div>
        <div className="mob_odnokaernye-sp__window5">
          <img
            src={img5.img}
            alt="window51"
            onMouseEnter={() => setImg5({ img: window52, isHover: true })}
            onMouseLeave={() => setImg5({ img: window51, isHover: false })}
          />
          <div
            className={
              img5.isHover
                ? 'mob_odnokaernye-sp__title-card5--visible'
                : 'mob_odnokaernye-sp__title-card5'
            }
            onMouseEnter={() => setImg5({ img: window52, isHover: true })}
            onMouseLeave={() => setImg5({ img: window51, isHover: false })}>
            <p>
              Однокамерные стеклопакет оптимальны в регионах с мягким климатом, где среднезимние
              температуры не опускаются ниже -8°C. <br /> Для остекления балконов и лоджий,
              используемых в качестве хранилища или не требующих высокой теплоизоляции. <br /> В
              помещениях с дополнительной защитой от холода, например, если окно выходит на
              остекленную лоджию.
            </p>
          </div>
        </div>
        <div className="mob_odnokaernye-sp__window6">
          <img
            src={img6.img}
            alt="window61"
            onMouseEnter={() => setImg6({ img: window62, isHover: true })}
            onMouseLeave={() => setImg6({ img: window61, isHover: false })}
          />
          <div
            className={
              img6.isHover
                ? 'mob_odnokaernye-sp__title-card6--visible'
                : 'mob_odnokaernye-sp__title-card6'
            }
            onMouseEnter={() => setImg6({ img: window62, isHover: true })}
            onMouseLeave={() => setImg6({ img: window61, isHover: false })}>
            <p>
              Однокамерные стеклопакеты моно использовать для офисных зданий и производственных
              помещений, где требования к тепло- и звукоизоляции могут быть ниже, чем в жилых домах.
              <br /> На дачах и в загородных домах летнего проживания, где не требуется высокая
              защита от холода.
            </p>
          </div>
        </div>

        <div className="mob_odnokaernye-sp__text">
          <p>
            Важно отметить, что при выборе стеклопакетов для своего дома или квартиры следует
            учитывать не только климатические условия и местоположение, но и индивидуальные
            потребности в тепло- и звукоизоляции. <br />В некоторых случаях может быть целесообразно
            рассмотреть возможность установки двухкамерных стеклопакетов, которые обеспечивают
            лучшую тепло- и звукоизоляцию по сравнению с однокамерными.
          </p>
        </div>
      </div>
      <Component13 />
    </>
  );
};
export default Odnokamernye_sp_mob;
