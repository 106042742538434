import React from 'react';
import './ModalWindow.css';

const ModalWindow = ({ visible, onClose }) => {
  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className={`modal-window ${visible ? 'modal-window--visible' : ''}`}
      onClick={handleOverlayClick}>
      <div className="modal-window__center">
        <p>Контент модального окна</p>
      </div>
    </div>
  );
};

export default ModalWindow;
