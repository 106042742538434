import React from 'react';
import PlasticWindowBlock from "./PlasticWindow";
import WarmIsolation from "./WarmIsolation";
import ProfileWidth from "./ProfileWidth";
import SoundIsolation from "./SoundIsolation";
import PageHeader from "../../ui/PageHeader";
import {Stack, useMediaQuery, useTheme} from "@mui/material";
import Component13 from '../../components/Elements/mainPage/Component13.jsx';
import UpperMenu from "../../components/Elements/UpperMenu.jsx";
import image from './images/pageheadreImage.svg'
import mobile from './images/mobileImage.svg'

const Balconies = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <React.Fragment>
            <UpperMenu />
            <Stack sx={{
                paddingLeft: isMobile ? 0 : 10,
                paddingRight: isMobile ? 0 : 10,
                marginTop: isMobile ? 7 : 12,
                backgroundColor: '#FDA8A8'
            }}>
                <PageHeader mobileImage={mobile} image={image}/>
                <PlasticWindowBlock/>
                {/*<WarmIsolation/>*/}
                {/*<ProfileWidth/>*/}
                {/*<SoundIsolation/>*/}
            </Stack>
            <Component13/>
        </React.Fragment>
    );
};

export default Balconies;
