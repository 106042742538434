import React from 'react';

const ContactUs = ({
    color = 'white',
                   }) => {
    return (
        <svg width="43" height="37" viewBox="0 0 43 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M30.7109 0H10.2943C4.16927 0 0.0859375 2.75 0.0859375 9.16667V22C0.0859375 28.4167 4.16927 31.1667 10.2943 31.1667H17.583C18.7876 31.1667 19.7676 30.2133 19.6043 29.1317C19.4001 27.83 19.4409 26.4733 19.7472 25.08C20.8293 20.3133 25.1984 16.5183 30.5476 15.73C33.2222 15.345 35.7743 15.675 38.0201 16.555C39.388 17.0867 40.9193 16.2433 40.9193 14.905V9.16667C40.9193 2.75 36.8359 0 30.7109 0ZM31.6705 10.2483L25.2801 14.8317C23.9326 15.8033 22.2176 16.28 20.5026 16.28C18.7876 16.28 17.0522 15.8033 15.7251 14.8317L9.33469 10.2483C8.68135 9.77167 8.57927 8.91 9.08969 8.305C9.62052 7.71833 10.5801 7.60833 11.2334 8.085L17.6239 12.6683C19.1755 13.7867 21.8093 13.7867 23.3609 12.6683L29.7514 8.085C30.4047 7.60833 31.3847 7.7 31.8951 8.305C32.4259 8.91 32.3239 9.77167 31.6705 10.2483Z"
                fill={color}/>
            <path
                d="M32.7474 18.3334C27.1124 18.3334 22.5391 22.4217 22.5391 27.5C22.5391 32.56 27.1124 36.6667 32.7474 36.6667C38.4028 36.6667 42.9557 32.56 42.9557 27.5C42.9557 22.4217 38.4028 18.3334 32.7474 18.3334ZM36.9328 26.675L36.1774 27.335L31.7266 31.35C31.5224 31.515 31.1345 31.6984 30.8486 31.735L28.8478 31.9917C28.1332 32.0834 27.6228 31.625 27.7249 30.9834L28.0107 29.1867C28.0516 28.93 28.2353 28.5817 28.4395 28.3984L32.8903 24.42L33.6253 23.7417C34.1153 23.3017 34.6666 23.0084 35.2382 23.0084C35.7282 23.0084 36.2795 23.21 36.892 23.7417C38.2599 24.9334 37.8311 25.8684 36.9328 26.675Z"
                fill={color}/>
        </svg>


    );
};

export default ContactUs;
