import React from 'react';
import { Box, Typography, Grid, Stack, useMediaQuery } from '@mui/material';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import image1 from './images/1.jpg';
import image2 from './images/2.jpg';
import image3 from './images/3.jpg';

interface WindowType {
    title: string;
    description: string[];
    image: string;
}

const windowTypes: WindowType[] = [
    {
        title: 'Факторы, влияющие на звукоизоляцию',
        description: [
            'Тип стеклопакета - Одинарные стеклопакеты хуже справляются с шумом, чем двух- или трехкамерные. Чем больше камер в стеклопакете, тем лучше он изолирует помещение от внешних звуков.',
            'Ширина профиля - Чем шире профиль окна, тем лучше звукоизоляция. Профили с большей глубиной позволяют устанавливать более толстые стеклопакеты, а также имеют большее количество камер внутри профиля, что усиливает защиту от шума.',
            'Герметизация - Высококачественные уплотнители и герметизация по всему периметру окна предотвращают проникновение шума через щели и стыки. Использование нескольких контуров уплотнения дополнительно улучшает звукоизоляционные свойства.'
        ],
        image: image1,
    },
    {
        title: 'Типы стеклопакетов для лучшей звукоизоляции',
        description: [
            'Двухкамерные стеклопакеты - Состоящие из трех стекол и двух воздушных камер, они значительно уменьшают шумовые колебания. Эти стеклопакеты идеально подходят для шумных районов, создавая комфортные условия в помещении.',
        ],
        image: image2,
    },
    {
        title: 'Роль ширины профиля в звукоизоляции',
        description: [
            'Увеличенная глубина - Широкие профили позволяют устанавливать более толстые стеклопакеты, что улучшает звукоизоляцию. Большая глубина профиля создает больше пространства для воздушных камер, которые эффективно гасят звуковые волны.',
            'Улучшенная герметизация - Более широкий профиль предоставляет возможность для установки нескольких контуров уплотнения, что минимизирует проникновение шума через щели и стыки.'
        ],
        image: image3,
    }
];

const theme = createTheme({
    typography: {
        h5: {
            fontSize: '24px',
            '@media (max-width:600px)': {
                fontSize: '20px',
            },
        },
        h6: {
            fontSize: '20px',
            '@media (max-width:600px)': {
                fontSize: '18px',
            },
        },
        subtitle1: {
            fontSize: '18px',
            '@media (max-width:600px)': {
                fontSize: '16px',
            },
        },
        body1: {
            fontSize: '16px',
            '@media (max-width:600px)': {
                fontSize: '14px',
            },
        },
        body2: {
            fontSize: '14px',
            '@media (max-width:600px)': {
                fontSize: '12px',
            },
        },
    },
});

const SoundIsolation = () => {
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ padding: 3 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', textAlign: 'left', marginBottom: 2, fontFamily: 'Unbounded' }}>
                    Звукоизоляция
                </Typography>
                <Grid container spacing={2}>
                    {windowTypes.map((window) => (
                        <Grid item xs={12} sm={12} md={12} key={window.title}>
                            {isMobile && (
                                <Box
                                    component="img"
                                    src={window.image}
                                    alt={window.title}
                                    width='100%'
                                    sx={{
                                        borderRadius: 4,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        margin: '0 auto',
                                        borderBottomRightRadius: { sm: 0, xs: 0 },
                                        borderBottomLeftRadius: { sm: 0, xs: 0 },
                                    }}
                                />
                            )}
                            <Box sx={{
                                padding: 2,
                                backgroundColor: '#F5F5F5',
                                borderRadius: 4,
                                borderTopRightRadius: { sm: 0, xs: 0, md: 16 },
                                borderTopLeftRadius: { sm: 0, xs: 0, md: 16 },
                                display: 'flex', alignItems: 'center'
                            }}>
                                <Stack sx={{ flexGrow: 1, width: '100%' }}>
                                    <Typography variant="h6" sx={{ fontFamily: 'Unbounded' }}>
                                        {window.title}
                                    </Typography>
                                    <Stack spacing={0.5}>
                                        {window.description.map((desc, descIndex) => (
                                            <Typography key={descIndex} variant="body2" sx={{ textAlign: 'justify', wordBreak: 'break-word' }}>
                                                • {desc}
                                            </Typography>
                                        ))}
                                    </Stack>
                                </Stack>
                                {!isMobile && (
                                    <Box
                                        component="img"
                                        src={window.image}
                                        alt={window.title}
                                        sx={{ width: 300, height: 'auto', borderRadius: 4, marginLeft: 2 }}
                                    />
                                )}
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </ThemeProvider>
    );
};

export default SoundIsolation;
