import React from 'react';

const BespltnyZamer = () => {

    return (
        <>

       <p>
        Бесплатный замер
       </p>

        </>
    )
}

export default BespltnyZamer;