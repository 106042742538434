import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Select from 'react-select';
import Slider from 'react-slick';
import LazyLoad from 'react-lazyload';

import './galery.css';
import header_bg from './images/header-bg.svg';
import header_bg_mob from './images/header-bg_mob.svg';

// photos
import photo1 from './images/1.JPG';
import photo2 from './images/2.JPG';
import photo3 from './images/3.JPG';
import photo4 from './images/4.JPG';
import photo5 from './images/5.JPG';
import photo6 from './images/6.JPG';
import photo7 from './images/7.JPG';
import photo8 from './images/8.JPG';
import photo9 from './images/9.JPG';
import photo10 from './images/10.JPG';
import photo11 from './images/11.JPG';
import photo12 from './images/12.JPG';
import photo13 from './images/13.JPG';

import UpperMenu from '../../Elements/UpperMenu.jsx';
import Component13 from '../../Elements/mainPage/Component13.jsx';
import ContactForm from '../../contact-form/ContactForm.jsx';

const categories = [
  {
    name: 'Коммерческие объекты',
    images: { 1: photo1, 2: photo2, 3: photo3, 4: photo10 }
  },
  {
    name: 'Микрорайоны',
    images: { 1: photo4, 2: photo6, 3: photo7, 4: photo9, 5: photo13 }
  },
  {
    name: 'Административные здания',
    images: { 1: photo12, 2: photo8, 3: photo11, 4: photo5 }
  }
];

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const sections = categories.map((e, index) => ({ value: index + 1, label: e.name }));

const photos = categories.reduce((acc, category, index) => {
  acc[index + 1] = Object.values(category.images);
  return acc;
}, {});

const customStyles = {
  control: (provided) => ({
    ...provided,
    fontSize: '3vw',
    width: '90vw',
    marginLeft: 'auto',
    marginRight: 'auto'
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: '3vw',
    width: '90vw',
    marginLeft: '5vw'
  })
};

const Galery = () => {
  const menuArr = ['О компании', 'История', 'Лицензии', 'Партнерам', 'Поставщикам', 'Документы'];
  const [photoState, setPhotoState] = useState({
    name: categories[0].name,
    img: categories[0].images
  });

  const [activePage, setActivePage] = useState('');
  const [isContactFormVisible, setContactFormVisible] = useState(false);
  const [barState, setBarState] = useState(categories[0].name);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedIdx, setSelectedIdx] = useState(0);

  const handleToggleContactForm = () => {
    setContactFormVisible(!isContactFormVisible);
  };

  const handleModalClose = () => {
    setContactFormVisible(false);
  };

  const handleFunc = (value, label) => {
    setSelectedOption({ label });
    setSelectedIdx(value - 1);
  };

  const [isDesktop, setIsDesktop] = useState(window.innerWidth);
  const handleResize = () => {
    setIsDesktop(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const nextButton = document.querySelector('.slick-next');
    const nextButton1 = document.querySelector('.slick-arrow');

    if (nextButton || nextButton1) {
      nextButton.style.display = 'none';
      nextButton1.style.display = 'none';
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Пластиковые Окна Окна ПВХ</title>
        <meta
          name="description"
          content="Пластиковые окна

          Пластиковые окна Пластиковые окна — это современное и практичное решение для вашего дома или квартиры. Они обладают множеством преимуществ, которые делают их лучшим выбором для остекления любых помещений. Вот некоторые из них:
          
          - Изделия из ПВХ имеют высокую теплоизоляцию и энергоэффективность. Они не пропускают холод и сквозняки, сохраняют тепло внутри и снижают расходы на отопление. Такие окна также защищают от перегрева в жаркое время года, благодаря специальным стеклопакетам с солнцезащитой.
          - Эти конструкции обеспечивают хорошую защиту от шума. Они изолируют внешние звуки и создают тихую и уютную атмосферу в доме. Они также устойчивы к взлому и повреждениям, благодаря качественной фурнитуре и противовзломным системам.
          - Оконные рамы из пластика легко устанавливаются и обслуживаются. Они не нуждаются в покраске или ремонте, не коррозируют и не деформируются. Они легко моются и не теряют свой цвет и блеск со временем. Они также имеют долгий срок службы — до 50 лет и более.
          - Оконные блоки из пластика имеют разнообразный дизайн и подходят для любого интерьера. Вы можете выбрать их любого вида, размера и формы: арочные, эркерные, панорамные, раздвижные, подъемно-раздвижные и другие. Вы также можете выбрать их любого цвета: белые, черные, цветные, ламинированные под дерево или камень.
          - Они подходят для остекления любых помещений: квартир, домов, балконов, лоджий, террас, веранд, детских, коттеджей и других. Они создают комфорт и улучшают микроклимат в помещениях. Они обеспечивают свежий воздух и оптимальную влажность, благодаря системам вентиляции и конденсата. Они также предотвращают появление плесени и грибка, которые могут нанести вред здоровью.
          - Они экологичны и безопасны для окружающей среды. Они не содержат вредных веществ и не выделяют токсичных газов. Они также экономят электроэнергию и снижают выбросы углекислого газа. Они изготавливаются из перерабатываемых материалов и могут быть утилизированы после окончания срока службы.
          Как вы видите, пластиковые окна — это идеальный вариант для вашего дома или квартиры. Они сочетают в себе качество, функциональность, эстетику и экономию. "
        />
        <meta property="og:title" content="Пластиковые Окна Окна ПВХ" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://ok-schtern.ru/" />
        <meta property="og:image" content="URL_ИЗОБРАЖЕНИЯ" />
        <meta
          property="og:description"
          content="Пластиковые окна — это современное и практичное решение для вашего дома или квартиры."
        />
        <meta property="og:site_name" content="SCHTERN" />
        <meta property="og:locale" content="ru_RU" />
      </Helmet>
      <UpperMenu />

      {isDesktop > 480 ? (
        <>
          <div className="galery">
            <div className="galery__header">
              <img src={header_bg} alt="header" />
            </div>
            <div className="galery__our-works">
              <h2 className="galery__our-works--h2">Наши работы</h2>
              <div className="galery__our-works--header">
                {categories.map((o) => (
                  <div
                    key={o.name}
                    className="galery__our-works--header-element"
                    onClick={() => {
                      setBarState(o.name);
                      setPhotoState({
                        name: o.name,
                        img: categories.find((e) => e.name === o.name).images
                      });
                    }}
                    style={{ color: barState === o.name ? '#87DFEE' : '#161E54' }}>
                    {o.name}
                  </div>
                ))}
              </div>
              <div className="galery__images">
                <div className="galery__grids-containers">
                  <div className="galery__grids-container-top">
                    {Object.values(photoState.img).map((value, index) => (
                      <div key={index} className="galery__grids-container-top-1">
                        <LazyLoad height={200} offset={100}>
                          <img src={value} alt="" style={{ width: '47vw' }} />
                        </LazyLoad>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="mob-galery__header">
            <img src={header_bg_mob} alt="header" />
          </div>

          <div className="mob_galery__our-works">
            <h2 className="mob_galery__our-works-h2">Наши работы</h2>
            <Select
              options={sections}
              value={selectedOption}
              onChange={(selectedOption) => handleFunc(selectedOption.value, selectedOption.label)}
              className="select-dropdown"
              isSearchable={false}
              placeholder="Выберите объект"
              styles={customStyles}
            />

            <Slider {...settings}>
              {photos[selectedIdx + 1]?.map((photo, index) => (
                <div key={index}>
                  <LazyLoad height={200} offset={100}>
                    <img className="photo-c10" alt={`photo${index + 1}`} src={photo} />
                  </LazyLoad>
                </div>
              ))}
            </Slider>
          </div>
        </>
      )}

      <Component13 />
      {isContactFormVisible && (
        <div className="modal-overlay">
          <ContactForm onClose={handleModalClose} />
        </div>
      )}
    </>
  );
};

export default Galery;
