import React, { useState, useEffect } from 'react';
import './buy-profile.css';
import InputMask from 'react-input-mask';
import person from './Images/person.svg';
import person_mob from './Images/person_mob.svg';
import cls_btn from './Images/close-button.svg';
import UpperMenu from '../Elements/UpperMenu.jsx';
import Component13 from '../Elements/mainPage/Component13.jsx';
import PartneramBody from '../pages/partneram/PartneramBody.jsx';

const BuyProfile = ({ onClose }) => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth);
  const handleResize = () => {
    setIsDesktop(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [city, setCity] = useState('');
  const [adress, setAdress] = useState('');
  const [comment, setComment] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('+7');
  const [email, setEmail] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const handlePhoneChange = (e) => {
    const cleanedValue = e.target.value.replace(/\D/g, ''); // Оставляем только цифры
    setPhoneNumber(cleanedValue);
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);

    if (!validateEmail(emailValue)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const handleSubmit = async () => {
    // Проверяем корректность номера телефона перед отправкой
    if (phoneNumber.length !== 11) {
      setPhoneNumberError(true);
      return;
    }

    try {
      // Отправляем данные на сервер
      const response = await fetch('https://schtern-mail.ru/send-diller', {
        // const response = await fetch('http://localhost:3001/send-diller', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          title: 'покупке профиля',
          firstName,
          lastName,
          phoneNumber,
          email,
          companyName,
          city,
          adress,
          comment
        })
      });

      if (response.ok) {
        console.log('Email sent successfully');
        setPhoneNumberError(false);
        setEmailError(false);
        setAdress('');
        setCity('');
        setComment('');
        setCompanyName('');
        setEmail('');
        setPhoneNumber('');
      } else {
        console.error('Error sending email');
      }
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  return (
    <>
      {isDesktop > 480 ? (
        <>
          <UpperMenu />
          <div className="main-buy-profile">
            <h2>Купить профиль SCHTERN</h2>
            <div className="buy-profile">
              <div className="diller-img-contact_us">
                <img src={person} alt="person" />
              </div>

              <div className="buy-profile-container">
                <p className="form-heading">Основные сведения</p>
                <form>
                  <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="Имя"
                  />

                  <input
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder="Фамилия"
                  />

                  <InputMask
                    mask="+7 999-999-99-99"
                    value={phoneNumber}
                    onChange={handlePhoneChange}
                    placeholder="Номер телефона"
                  />
                  {phoneNumberError && (
                    <p className="error-message">Пожалуйста, укажите корректный номер телефона</p>
                  )}

                  <InputMask value={email} onChange={handleEmailChange} placeholder="Email" />
                  {emailError && (
                    <p className="error-message">
                      Пожалуйста, введите корректный адрес электронной почты
                    </p>
                  )}

                  <p className="form-heading">О вашей комании</p>

                  <input
                    type="text"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    placeholder="Название комании"
                  />
                  <input
                    type="text"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    placeholder="Город"
                  />
                  <input
                    type="text"
                    value={adress}
                    onChange={(e) => setAdress(e.target.value)}
                    placeholder="Адрес"
                  />
                  <input
                    type="text"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder="Комментарий к заявке"
                  />

                  <button type="button" onClick={handleSubmit}>
                    Отправить
                  </button>
                </form>
                <p>Нажимая, вы соглашаетесь с условиями политики конфиденциальности</p>
              </div>
            </div>
          </div>
          <Component13 />
        </>
      ) : (
        <>
          <UpperMenu />
          <div className="main-buy-profile">
            <h2>Купить профиль SCHTERN</h2>
            <div className="buy-profile">
              <div className="buy-profile-container">
                <p className="form-heading">Основные сведения</p>
                <form>
                  <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="Имя"
                  />

                  <input
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder="Фамилия"
                  />

                  <InputMask
                    mask="+7 999-999-99-99"
                    value={phoneNumber}
                    onChange={handlePhoneChange}
                    placeholder="Номер телефона"
                  />
                  {phoneNumberError && (
                    <p className="error-message">Пожалуйста, укажите корректный номер телефона</p>
                  )}
                  <InputMask value={email} onChange={handleEmailChange} placeholder="Email" />
                  {emailError && (
                    <p className="error-message">
                      Пожалуйста, введите корректный адрес электронной почты
                    </p>
                  )}

                  <p className="form-heading">О вашей комании</p>

                  <input
                    type="text"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    placeholder="Название комании"
                  />
                  <input
                    type="text"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    placeholder="Город"
                  />
                  <input
                    type="text"
                    value={adress}
                    onChange={(e) => setAdress(e.target.value)}
                    placeholder="Адрес"
                  />
                  <input
                    type="text"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder="Комментарий к заявке"
                  />

                  <button type="button" onClick={handleSubmit}>
                    Отправить
                  </button>
                  <p style={{ fontSize: '2vw' }}>
                    Нажимая, вы соглашаетесь с условиями политики конфиденциальности
                  </p>
                </form>
              </div>
              <div className="img-contact_us">
                <img src={person_mob} alt="person" />
              </div>
            </div>
          </div>
          <Component13 />
        </>
      )}
    </>
  );
};
export default BuyProfile;
