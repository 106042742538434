import React, { useState, useEffect } from 'react';
import '../../../css/mainPage/component10.css'; // Импорт соответствующего файла стилей
import illustration from '../../../assest/svgEl/main/illustration-c10.svg';
import Select from 'react-select';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

import photo1 from '../../../assest/photo/1.JPG';
import photo2 from '../../../assest/photo/2.JPG';
import photo3 from '../../../assest/photo/3.JPG';
import photo4 from '../../../assest/photo/4.JPG';
import photo5 from '../../../assest/photo/5.JPG';
import photo6 from '../../../assest/photo/6.JPG';
import photo7 from '../../../assest/photo/7.JPG';
import photo8 from '../../../assest/photo/8.JPG';
import photo9 from '../../../assest/photo/9.JPG';
import photo10 from '../../../assest/photo/10.JPG';
import photo11 from '../../../assest/photo/11.JPG';
import photo12 from '../../../assest/photo/12.JPG';
import photo13 from '../../../assest/photo/13.JPG';
import OpenImage from './OpenImage';

const customStyles = {
  control: (provided) => ({
    ...provided,
    fontSize: '3vw',
    width: '90vw',
    marginLeft: 'auto',
    marginRight: 'auto'
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: '3vw',
    width: '90vw',
    zIndex: 1000000
  })
};

const Component10 = () => {
  const [selectedIdx, setSelectedIdx] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const sections = [
    { value: 1, label: 'Коммерческие объекты' },
    { value: 2, label: 'Микрорайоны' },
    { value: 3, label: 'Административные здания' }
  ];

  const handleFunc = (value, label) => {
    setSelectedOption({ label });
    setSelectedIdx(value - 1);
  };

  const handleFuncFS = (index) => {
    setSelectedOption({ label: sections[index].label });
    setSelectedIdx(index);
  };

  const imgNum = ['img1', 'img2', 'img3', 'img4', 'img5', 'img6', 'img7'];
  const photos = {
    1: [photo1, photo2, photo3, photo10],
    2: [photo4, photo6, photo7, photo9, photo13],
    3: [photo12, photo8, photo11, photo5]
  };
  useEffect(() => {
    const nextButton = document.querySelector('.slick-next');
    const nextButton1 = document.querySelector('.slick-arrow');

    if (nextButton || nextButton1) {
      nextButton.style.display = 'none';
      nextButton1.style.display = 'none';
    }
  }, []);

  const [imageOpen, setImageOpen] = useState(false);

  const openImageViewer = () => {
    setImageOpen(true);
  };

  const closeImageViewer = () => {
    setImageOpen(false);
  };

  const [isDesktop, setIsDesktop] = useState(window.innerWidth);
  const handleResize = () => {
    setIsDesktop(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <div className="component10">
      <h2>Наши работы</h2>

      {isDesktop > 480 ? (
        <div style={{ marginTop: '3vw' }}>
          <img className="photo-c10" src={photos[selectedIdx + 1]?.[0]} />
          {/* {imageOpen && (
            <OpenImage imageUrl={photos[selectedIdx + 1]?.[0]} onClose={closeImageViewer} />
          )} */}
        </div>
      ) : (
        <>
          <div style={{ position: 'absolute', left: '5vw', zIndex: 9999999 }}>
            <Select
              options={sections}
              value={selectedOption}
              onChange={(selectedOption) => handleFunc(selectedOption.value, selectedOption.label)}
              className="select-dropdown"
              isSearchable={false}
              placeholder="Выберите объект"
              styles={customStyles}
            />
          </div>

          <Slider {...settings} className="slider-c10">
            {photos[selectedIdx + 1]?.map((photo, index) => (
              <div key={index} className="slider-box-c10">
                <img className="photo-c10" alt={`photo${index + 1}`} src={photo} />
              </div>
            ))}
          </Slider>
        </>
      )}

      <div className="illustration-c10">
        <img src={illustration} alt="illustration-woman-c10" className="illustration-c10-img" />
      </div>
      <div className="navigation-bar-c10">
        {isDesktop > 480 ? (
          <>
            {sections.map((section, index) => (
              <div
                key={index}
                className={`nav-item ${index === selectedIdx ? 'selected-c10' : ''}`}
                onClick={() => handleFuncFS(index)}>
                {section.label}
              </div>
            ))}
          </>
        ) : (
          <></>
        )}
      </div>

      <Link to={'/galery'}>
        {' '}
        <div className="projects-c10">Все проекты</div>
      </Link>
    </div>
  );
};

export default Component10;
