import React, { useState, useEffect } from 'react';
import '../../../css/mainPage/component1.css';
import buildings from '../../../assest/svgEl/bldngs.svg';
import buildings_mob from '../../../assest/svgEl/buildings_mob.svg';
import clouds from '../../../assest/svgEl/clouds.svg';
import { Link } from 'react-router-dom';
import ContactForm from '../../contact-form/ContactForm';

const Component1 = ({ handleToggleContactForm }) => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth);

  const handleResize = () => {
    setIsDesktop(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      {isDesktop <= 480 ? (
        <div className="component1">
          <img src={clouds} alt="bg" className="svg-image1" />
          <img src={buildings_mob} alt="buildings" className="svg-image2" />
          <div className="info-block">
            <div className="info-text">
              <p className="info-title">3 года</p>
              <p className="info-subtitle" style={{ whiteSpace: 'nowrap' }}>
                гарантийный срок
              </p>
            </div>
            <div className="info-text">
              <p className="info-title">Надежность</p>
              <p className="info-subtitle">
                заполнение стеклопакетов <br />
                смесью газов Аргон/Криптон
              </p>
            </div>
            <div className="info-text">
              <p className="info-title">Технологии</p>
              <p className="info-subtitle">
                уникальная для России технология
                <br /> вклейки стеклопакетов
              </p>
            </div>
          </div>
          <div className="custom-text-block">
            <p className="custom-text-title">
              Немецкое качество.<br></br> Доступно в России.
            </p>
            <p className="custom-text-content">Оконный комбинат</p>
            <p className="custom-text-content">светопрозрачных</p>
            <p className="custom-text-content">конструкций и</p>
            <p className="custom-text-content">профильных систем</p>
          </div>

          <p className="calculator">Рассчитать стоимость</p>

          <p className="measurement" onClick={handleToggleContactForm}>
            Заказать бесплатный замер
          </p>
        </div>
      ) : (
        <div className="component1">
          <img src={clouds} alt="bg" className="svg-image1" />
          <img src={buildings} alt="buildings" className="svg-image2" />
          <div className="info-block">
            <div className="info-text">
              <p className="info-title">3 года</p>
              <p className="info-subtitle">гарантийный срок</p>
            </div>
            <div className="info-text">
              <p className="info-title">Надежность</p>
              <p className="info-subtitle">
                заполнение
                <br /> стеклопакетов
                <br /> смесью газов
                <br /> Аргон/
                <br />
                Криптон
              </p>
            </div>
            <div className="info-text">
              <p className="info-title">Технологии</p>
              <p className="info-subtitle">
                уникальная
                <br /> для России
                <br />
                технология
                <br /> вклейки
                <br /> стеклопакетов
              </p>
            </div>
          </div>
          <div className="custom-text-block">
            <p className="custom-text-title">
              Немецкое качество.<br></br> Доступно в России.
            </p>
            <p className="custom-text-content">
              Оконный комбинат <br /> светопрозрачных <br />
              конструкций и <br />
              профильных систем
            </p>
          </div>

          <div className="buttons-container">
            <p className="calculator">Рассчитать стоимость</p>
            <p className="measurement" onClick={handleToggleContactForm}>
              Заказать бесплатный замер
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Component1;
