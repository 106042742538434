import React, { Component, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import './Katalog.css';
import leftBar from './images/left-bar.svg';
import KatalogItem from './component/KatalogItem.jsx';
import ModalWindow from './component/ModalWindow.jsx';
import UpperMenu from '../../Elements/UpperMenu.jsx';
import Component13 from '../../../components/Elements/mainPage/Component13.jsx';
import { Helmet } from 'react-helmet';

import okna from '../../../assest/svgEl/catalog/okna.svg';
import balkony from '../../../assest/svgEl/catalog/balkony.svg';
import aksessuary from '../../../assest/svgEl/catalog/aksessuary.svg';
import arrow from '../../../assest/svgEl/arrow.svg';
import arrowBlue from '../../../assest/svgEl/arrowBlue.svg';
import katalogArray from './katalog.js';

const accsesoares = [
  {
    title: 'Замки на окна',
    description: `Замки на окна предназначены для предотвращения полного открытия створок. 
    Это особенно важно в домах, где живут дети и домашние животные, так как такие замки обеспечивают их безопасность, оставляя возможность для проветривания.`
  },
  {
    title: 'Комплектующие',
    description:
      'Различные комплектующие, такие как винты, петли, запоры, используются для установки и ремонта окон. Эти элементы фурнитуры обеспечивают надежность и долговечность оконной конструкции, гарантируя ее правильную работу на протяжении долгого времени.'
  },
  {
    title: 'Уплотнители',
    description:
      'Уплотнители служат для улучшения тепло- и звукоизоляции в помещении. Они помогают поддерживать комфортную температуру в доме и снижают уровень шума с улицы, что делает проживание более уютным и тихим.'
  },
  {
    title: 'Штапик',
    description: `Штапик используется для крепления стеклопакета в раме окна. Он обеспечивает надежное и герметичное крепление стеклопакета, что важно для сохранения тепло- и звукоизоляционных свойств окна.`
  },
  {
    title: `Заглушки 
    \n для подоконника`,
    description:
      'Заглушки для подоконника придают окну законченный вид и защищают его от повреждений и загрязнений. Они помогают сохранить подоконник в хорошем состоянии, предотвращая его износ и продлевая срок службы.'
  }
];
const accsesoaresMob = [
  {
    title: 'Замки на окна',
    description: `Замки на окна предназначены для предотвращения полного открытия створок. 
    Это особенно важно в домах, где живут дети и домашние животные, так как такие замки обеспечивают их безопасность, оставляя возможность для проветривания.`
  },
  {
    title: 'Комплектующие',
    description:
      'Различные комплектующие, такие как винты, петли, запоры, используются для установки и ремонта окон. Эти элементы фурнитуры обеспечивают надежность и долговечность оконной конструкции, гарантируя ее правильную работу на протяжении долгого времени.'
  },
  {
    title: 'Уплотнители',
    description:
      'Уплотнители служат для улучшения тепло- и звукоизоляции в помещении. Они помогают поддерживать комфортную температуру в доме и снижают уровень шума с улицы, что делает проживание более уютным и тихим.'
  },
  {
    title: 'Штапик',
    description: `Штапик используется для крепления стеклопакета в раме окна. Он обеспечивает надежное и герме-
    тичное крепление стеклопакета, что важно для сохранения тепло- и звукоизоляционных свойств окна.`
  },
  {
    title: 'Заглушки для подоконника',
    description:
      'Заглушки для подоконника придают окну законченный вид и защищают его от повреждений и загрязнений. Они помогают сохранить подоконник в хорошем состоянии, предотвращая его износ и продлевая срок службы.'
  }
];

const Katalog = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState('');
  const [modalIsVisible, setModalIsVisible] = useState(false);

  const [isDesktop, setIsDesktop] = useState(window.innerWidth);
  const handleResize = () => {
    setIsDesktop(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const openModal = () => {
    setModalIsVisible(true);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setModalIsVisible(false);
    document.body.style.overflow = '';
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    if (modalIsVisible) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [modalIsVisible]);

  return (
    <>
      <Helmet>
        <title>Пластиковые Окна Окна ПВХ</title>
        <meta
          name="description"
          content="В этом разделе вы найдете разнообразные аксессуары и комплектующие для окон,
          которые помогают улучшить их функциональность и внешний вид.
          Они играют важную роль в обеспечении безопасности, комфорта и долговечности
          оконных конструкций.
          
          Эти аксессуары помогут вам сделать ваши окна более функциональными,
          безопасными и эстетически привлекательными. Выбирая подходящие товары, вы
          обеспечиваете себе комфортное и безопасное проживание в вашем жилище.
          Замки на окна предназначены для предотвращения полного открытия створок. 
    Это особенно важно в домах, где живут дети и домашние животные, так как такие замки обеспечивают их безопасность, оставляя возможность для проветривания.
    Различные комплектующие, такие как винты, петли, запоры, используются для установки и ремонта окон. Эти элементы фурнитуры обеспечивают надежность и долговечность оконной конструкции, гарантируя ее правильную работу на протяжении долгого времени.
    Уплотнители служат для улучшения тепло- и звукоизоляции в помещении. Они помогают поддерживать комфортную температуру в доме и снижают уровень шума с улицы, что делает проживание более уютным и тихим.
    Штапик используется для крепления стеклопакета в раме окна. Он обеспечивает надежное и герме-
    тичное крепление стеклопакета, что важно для сохранения тепло- и звукоизоляционных свойств окна.
    Заглушки для подоконника придают окну законченный вид и защищают его от повреждений и загрязнений. Они помогают сохранить подоконник в хорошем состоянии, предотвращая его износ и продлевая срок службы.
          "
        />
        <meta property="og:title" content="Пластиковые Окна Окна ПВХ" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://ok-schtern.ru/" />
        <meta property="og:image" content="URL_ИЗОБРАЖЕНИЯ" />
        <meta
          property="og:description"
          content="Пластиковые окна — это современное и практичное решение для вашего дома или квартиры."
        />
        <meta property="og:site_name" content="SCHTERN" />
        <meta property="og:locale" content="ru_RU" />
      </Helmet>
      <UpperMenu />
      {isDesktop > 480 ? (
        <>
          <div className="catalog-component">
            <h1 className="catalog-component__h1">Акксессуары и комплектующие</h1>
            <div className="left-navigation-catalog">
              <div className="navigation-pic-catalog" onClick={() => setSelectedMenuItem('')}>
                <img src={leftBar} alt="left_bar_image" />
              </div>
            </div>
            <div className="main-catalog">
              <div className="navigation-catalog">
                <div className="main-navigation-catalog">
                  {accsesoares?.map((item) => (
                    <div className="main-nav-katalog__item">
                      <span
                        key={item.title}
                        className="main-navigation-second-catalog"
                        style={selectedMenuItem === item.title ? { color: '#87DFEE' } : {}}
                        onClick={() => setSelectedMenuItem(item.title)}>
                        <ReactMarkdown>{item?.title}</ReactMarkdown>
                      </span>

                      <div>
                        <img
                          src={selectedMenuItem === item.title ? arrowBlue : arrow}
                          alt="arrow"
                          className={selectedMenuItem === item.title ? 'mob-katalog__arrow' : ''}
                          style={{ width: '0.7vw', marginLeft: '1vw' }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="text-katalog"></div>
              {selectedMenuItem ? (
                <>
                  <div className="text-katalog__text-descriprion">
                    <ReactMarkdown>
                      {accsesoares.find((e) => e?.title === selectedMenuItem)?.description}
                    </ReactMarkdown>
                  </div>
                  {/* <div className="models-katalog">
                    <KatalogItem openModal={openModal} />
                    <KatalogItem openModal={openModal} />
                    <KatalogItem openModal={openModal} />
                    <KatalogItem openModal={openModal} />
                    <KatalogItem openModal={openModal} />
                  </div> */}
                </>
              ) : (
                <div className="text-katalog__text-descriprion">
                  <ReactMarkdown>{`
В этом разделе вы найдете разнообразные аксессуары и комплектующие для окон,
которые помогают улучшить их функциональность и внешний вид.
Они играют важную роль в обеспечении безопасности, комфорта и долговечности
оконных конструкций.

Эти аксессуары помогут вам сделать ваши окна более функциональными,
безопасными и эстетически привлекательными. Выбирая подходящие товары, вы
обеспечиваете себе комфортное и безопасное проживание в вашем жилище.
                  `}</ReactMarkdown>
                </div>
              )}
            </div>

            <ModalWindow visible={modalIsVisible} onClose={closeModal} />
          </div>
        </>
      ) : (
        <>
          <div className="mob-catalog-component">
            <h1 className="mob-catalog-component__h1">Акксессуары и комплектующие</h1>
            <div className="mob-catalog__header">
              <div className="mob-left-navigation-catalog">
                <div className="mob-navigation-pic-catalog" onClick={() => setSelectedMenuItem('')}>
                  <img src={leftBar} alt="left_bar_image" />
                </div>
              </div>

              <div className="mob-main-navigation-catalog">
                {accsesoares?.map((item) => (
                  <div className="mob-main-nav-katalog__item">
                    <span
                      key={item.title}
                      className="mob-main-navigation__nav-element"
                      style={selectedMenuItem === item.title ? { color: '#87DFEE' } : {}}
                      onClick={() => setSelectedMenuItem(item.title)}>
                      {item.title}
                    </span>
                    <div>
                      <img
                        src={selectedMenuItem === item.title ? arrowBlue : arrow}
                        alt="arrow"
                        className={selectedMenuItem === item.title ? 'mob-katalog__arrow' : ''}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {selectedMenuItem ? (
              <>
                <div className="mob-text-katalog__text-descriprion">
                  <ReactMarkdown>
                    {accsesoaresMob.find((e) => e?.title === selectedMenuItem)?.description}
                  </ReactMarkdown>
                </div>
                {/* <div className="mob-main-catalog">
                  <KatalogItem openModal={openModal} />
                  <KatalogItem openModal={openModal} />
                  <KatalogItem openModal={openModal} />
                  <KatalogItem openModal={openModal} />
                  <KatalogItem openModal={openModal} />
                </div> */}
              </>
            ) : (
              <div className="mob-text-katalog__text-descriprion">
                <ReactMarkdown>{`
В этом разделе вы найдете разно-
образные аксессуары и комплектую-
щие для окон,
которые помогают улучшить их функциональность и внешний вид. 
Они играют важную роль в обеспечении безопасности, комфорта и долговечности
оконных конструкций.

Эти аксессуары помогут вам сделать ваши окна более функциональными,
безопасными и эстетически привлека-
тельными. Выбирая подходящие то-
вары, вы обеспечиваете себе комфортное и безопасное прожива-
ние в вашем жилище.
                  `}</ReactMarkdown>
              </div>
            )}

            <ModalWindow visible={modalIsVisible} onClose={closeModal} />
          </div>
        </>
      )}
      <Component13 />
    </>
  );
};

export default Katalog;
