import React, { useState } from 'react';
import { Card, CardMedia, Stack, useMediaQuery, useTheme } from '@mui/material';
import desktop from '../../src/assest/page-header/desc.svg';
import mobile from '../assest/page-header/mob.svg';

type PageHeaderProps = {
    mobileImage: string | React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    image: string | React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

const PageHeader: React.FC<PageHeaderProps> = ({
                                                   mobileImage,
                                                   image,
                    }) => {
    const [expandedIndex, setExpandedIndex] = useState(null);

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Stack sx={{ padding: 2 }}>
            <Card sx={{ borderRadius: 4 }}>
                <CardMedia
                    component="img"
                    image={isMobile ? mobileImage : image}
                    sx={{ borderRadius: 4 }}
                />
            </Card>
        </Stack>
    );
};

export default PageHeader;
