import React, { useState, useEffect } from 'react';
import '../../../css/mainPage/component4.css'; // Импорт соответствующего файла стилей
import clouds from '../../../assest/svgEl/main/clouds-c4.svg';
import build from '../../../assest/svgEl/main/build-c4.svg';
import advantage1 from '../../../assest/svgEl/main/advantage1-c4.svg';
import advantage2 from '../../../assest/svgEl/main/advantage2-c4.svg';
import advantage3 from '../../../assest/svgEl/main/advantage3-c4.svg';
import advantage4 from '../../../assest/svgEl/main/advantage4-c4.svg';
import advantage11 from '../../../assest/svgEl/main/advantage11-c4.svg';
import advantage21 from '../../../assest/svgEl/main/advantage21-c4.svg';
import advantage31 from '../../../assest/svgEl/main/advantage31-c4.svg';
import advantage41 from '../../../assest/svgEl/main/advantage41-c4.svg';

const Component4 = ({ handleToggleContactForm }) => {
  const [imageHovered, setImageHovered] = useState(null); // Создаем состояние для отслеживания наведения на изображение

  const handleImageHover = (index) => {
    setImageHovered(index);
  };

  const [isDesktop, setIsDesktop] = useState(window.innerWidth);
  const handleResize = () => {
    setIsDesktop(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="component4">
      <div className="top-section" />

      <div className="blue-block">
        <div className="overlay-image"></div>
        <div className="text-div">
          <div className="line">Есть вопросы?</div>
          <div className="line">Получите бесплатную консультацию</div>
        </div>
        <div className="call-div" onClick={handleToggleContactForm}>
          Заказать звонок
        </div>
        <img src={clouds} alt="Blue Block" className="blue-image" />
      </div>

      <div className="content-text">Преимущества наших окон</div>

      <div className="content">
        <div className="content-container">
          {isDesktop > 480 ? (
            <>
              <div
                className="content-container-image"
                onMouseEnter={() => handleImageHover(1)} // Индекс изображения 1
                onMouseLeave={() => handleImageHover(null)}
                style={{ zIndex: imageHovered === 1 ? 2 : 1 }}>

                <img
                  src={imageHovered === 1 ? advantage11 : advantage1}
                  alt="advantage1"
                  className="content-container-image-image"
                />
              </div>
              <div
                className="content-container-image"
                onMouseEnter={() => handleImageHover(2)} // Индекс изображения 2
                onMouseLeave={() => handleImageHover(null)}
                style={{ zIndex: imageHovered === 2 ? 2 : 1 }}>
                <img
                  src={imageHovered === 2 ? advantage21 : advantage2}
                  alt="advantage2"
                  className="content-container-image-image"
                />
              </div>
              <div
                className="content-container-image"
                onMouseEnter={() => handleImageHover(3)} // Индекс изображения 2
                onMouseLeave={() => handleImageHover(null)}
                style={{ zIndex: imageHovered === 3 ? 2 : 1 }}>
                <img
                  src={imageHovered === 3 ? advantage31 : advantage3}
                  alt="advantage3"
                  className="content-container-image-image"
                />
              </div>
              <div
                className="content-container-image"
                onMouseEnter={() => handleImageHover(4)} // Индекс изображения 2
                onMouseLeave={() => handleImageHover(null)}
                style={{ zIndex: imageHovered === 4 ? 2 : 1 }}>
                <img
                  src={imageHovered === 4 ? advantage41 : advantage4}
                  alt="advantage4"
                  className="content-container-image-image"
                />
              </div>
            </>
          ) : (
            <>
              <div className="content-container-image">
                <img src={advantage1} alt="advantage1" className="content-container-image-image" />
              </div>
              <div className="content-container-image">
                <img src={advantage2} alt="advantage2" className="content-container-image-image" />
              </div>
              <div className="content-container-image">
                <img src={advantage3} alt="advantage3" className="content-container-image-image" />
              </div>
              <div className="content-container-image">
                <img src={advantage4} alt="advantage4" className="content-container-image-image" />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Component4;
