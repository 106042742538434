import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import './dostavka.css';
import icon from './images/icon.svg';
import mainImg from './images/main-image.svg';
import numbers from './images/numbers.svg';
import number from './images/number.svg';
import coin from './images/coin.svg';
import bike from './images/bike.svg';

import UpperMenu from '../../Elements/UpperMenu.jsx';
import Component13 from '../../Elements/mainPage/Component13.jsx';
import ContactForm from '../../contact-form/ContactForm.jsx';

const Dostavka = () => {
  const menuArr = ['О компании', 'История', 'Лицензии', 'Партнерам', 'Поставщикам', 'Документы'];

  const [activePage, setActivePage] = useState('');

  const [isContactFormVisible, setContactFormVisible] = useState(false);

  const handleToggleContactForm = () => {
    setContactFormVisible(!isContactFormVisible);
  };

  const handleModalClose = () => {
    setContactFormVisible(false);
  };

  const [isDesktop, setIsDesktop] = useState(window.innerWidth);
  const handleResize = () => {
    setIsDesktop(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Пластиковые Окна Окна ПВХ</title>
        <meta
          name="description"
          content="Пластиковые окна

          Пластиковые окна Пластиковые окна — это современное и практичное решение для вашего дома или квартиры. Они обладают множеством преимуществ, которые делают их лучшим выбором для остекления любых помещений. Вот некоторые из них:
          
          - Изделия из ПВХ имеют высокую теплоизоляцию и энергоэффективность. Они не пропускают холод и сквозняки, сохраняют тепло внутри и снижают расходы на отопление. Такие окна также защищают от перегрева в жаркое время года, благодаря специальным стеклопакетам с солнцезащитой.
          - Эти конструкции обеспечивают хорошую защиту от шума. Они изолируют внешние звуки и создают тихую и уютную атмосферу в доме. Они также устойчивы к взлому и повреждениям, благодаря качественной фурнитуре и противовзломным системам.
          - Оконные рамы из пластика легко устанавливаются и обслуживаются. Они не нуждаются в покраске или ремонте, не коррозируют и не деформируются. Они легко моются и не теряют свой цвет и блеск со временем. Они также имеют долгий срок службы — до 50 лет и более.
          - Оконные блоки из пластика имеют разнообразный дизайн и подходят для любого интерьера. Вы можете выбрать их любого вида, размера и формы: арочные, эркерные, панорамные, раздвижные, подъемно-раздвижные и другие. Вы также можете выбрать их любого цвета: белые, черные, цветные, ламинированные под дерево или камень.
          - Они подходят для остекления любых помещений: квартир, домов, балконов, лоджий, террас, веранд, детских, коттеджей и других. Они создают комфорт и улучшают микроклимат в помещениях. Они обеспечивают свежий воздух и оптимальную влажность, благодаря системам вентиляции и конденсата. Они также предотвращают появление плесени и грибка, которые могут нанести вред здоровью.
          - Они экологичны и безопасны для окружающей среды. Они не содержат вредных веществ и не выделяют токсичных газов. Они также экономят электроэнергию и снижают выбросы углекислого газа. Они изготавливаются из перерабатываемых материалов и могут быть утилизированы после окончания срока службы.
          Как вы видите, пластиковые окна — это идеальный вариант для вашего дома или квартиры. Они сочетают в себе качество, функциональность, эстетику и экономию. "
        />
        <meta property="og:title" content="Пластиковые Окна Окна ПВХ" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://ok-schtern.ru/" />
        <meta property="og:image" content="URL_ИЗОБРАЖЕНИЯ" />
        <meta
          property="og:description"
          content="Пластиковые окна — это современное и практичное решение для вашего дома или квартиры."
        />
        <meta property="og:site_name" content="SCHTERN" />
        <meta property="og:locale" content="ru_RU" />
      </Helmet>
      <UpperMenu />

      {isDesktop > 480 ? (
        <>
          <div className="dostavka">
            <h2>Доставка по Липецку и области</h2>
            <div className="dostavka__1">
              <img src={mainImg} alt="main-image_otkosy" className="dostavka__main-image" />
              <div className="dostavka__1--text">
                Завод SCHTERN оказывает услуги по перевозке готовой продукции собственным автопарком
                по Липецку и Липецкой области , а также другим регионам центральной России.
              </div>
            </div>
            <div className="dostavka__2">
              <img src={icon} alt="" />
              <div className="dostavka__2--text">
                Оформите заявку на услугу, мы свяжемся с вами в ближайшее время и ответим на все
                интересующие вопросы.
              </div>
              <div className="dostavka__2--link" onClick={handleToggleContactForm}>
                Отправить заявку
              </div>
            </div>

            {/* <div className="dostavka__nav">
              {menuArr.map((item) => (
                <p
                  onClick={(event) => setActivePage(event.target.textContent)}
                  className={
                    activePage === item ? 'otkosy__nav--activeTitle' : 'otkosy__nav--title'
                  }>
                  {item}
                </p>
              ))}
            </div> */}
          </div>

          <div className="dostavka__info-container">
            <div className="dostavka__uslovia">
              <h2 className="dostavka__uslovia--h2">Условия доставки</h2>
              <div class="dostavka__uslovia--container">
                <div class="dostavka__uslovia--container-grid dostavka__uslovia--container-grid-1">
                  <div className="dostavka__numbers">500</div>
                  <div class="dostavka__uslovia--container-grid-text">
                    Стоимость доставки от пятиста рублей на один адрес в пределах Липецка
                  </div>
                </div>
                <div class="dostavka__uslovia--container-grid dostavka__uslovia--container-grid-2">
                  <img src={bike} alt="bike" style={{ width: '12vw' }} />
                  <div class="dostavka__uslovia--container-grid-text">
                    В случае доставки продукции из ПВХ за пределы города Липецк стоимость доставки
                    необходимо уточнять у менеджера
                  </div>
                </div>
                <div class="dostavka__uslovia--container-grid dostavka__uslovia--container-grid-3">
                  <div className="dostavka__numbers">1</div>
                  <div class="dostavka__uslovia--container-grid-text">
                    Контрольное оповещение происходит за день до назначенной даты доставки
                  </div>
                </div>
                <div class="dostavka__uslovia--container-grid dostavka__uslovia--container-grid-4">
                  <img src={coin} alt="coin" style={{ width: '8vw' }} />
                  <div class="dostavka__uslovia--container-grid-text">
                    За 1 час перед доставкой водитель оповестит Вас. Повторная доставка продукции
                    является платной в случае, если клиент не смог принять заказ в назначенный день
                  </div>
                </div>
              </div>
            </div>
            <div className="dostavka__uslovia2">
              <h2 className="dostavka__uslovia--h2">Условия доставки</h2>

              <div className="dostavka__uslovia2--container">
                <div className="dostavka__uslovia2--container-element">
                  Для проведения разгрузочных работ клиент обязан предоставить условия для разгрузки
                  изделий: обеспечить подъезд машины к месту разгрузки не более 20 метров, в
                  строении должны присутствовать лестничные пролеты либо деревянные настилы
                  способные выдержать вес не менее 300 кг. При отсутствии, какого либо выше
                  перечисленного условия, сотрудники отдела доставки вправе отказаться от разгрузки
                  изделий до устранения препятствий для разгрузки. 
                </div>
                <div className="dostavka__uslovia2--container-element">
                  Стоимость подъёма продукции зависит от площади изделия, окна площадью до 3,5м²
                  оплачиваются из расчета 100 руб. за этаж, площадью более 3,5 м² оплачиваются из
                  расчета 200 руб за этаж. При наличии грузового лифта (изделие помещается в лифте),
                  оплата производится за первый и последний этажи.
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="mob-dostavka">
            <h2>Доставка</h2>
            <div className="mob-dostavka__1">
              <img src={mainImg} alt="main-image_mob-otkosy" className="mob-dostavka__main-image" />
              <div className="mob-dostavka__1--text">
                На сегодняшний день откосы делают при помощи штукатурного раствора, из
                сэндвич-панелей или листового пластика.
              </div>
            </div>
            <div className="mob-dostavka__2">
              <img src={icon} alt="" />
              <div className="mob-dostavka__2--container">
                <div className="mob-dostavka__2--text">
                  Оформите заявку на услугу, мы свяжемся с вами в ближайшее время и ответим на все
                  интересующие вопросы.
                </div>
                <div className="mob-dostavka__2--link" onClick={handleToggleContactForm}>
                  Отправить заявку
                </div>
              </div>
            </div>

            {/* <div className="mob-dostavka__nav">
              {menuArr.map((item) => (
                <p
                  onClick={(event) => setActivePage(event.target.textContent)}
                  className={
                    activePage === item ? 'mob-otkosy__nav--activeTitle' : 'mob-otkosy__nav--title'
                  }>
                  {item}
                </p>
              ))}
            </div> */}
          </div>

          <div className="mob-dostavka__info-container">
            <div className="mob-dostavka__uslovia">
              <h2 className="mob-dostavka__uslovia--h2">Условия доставки</h2>
              <div class="mob-dostavka__uslovia--container">
                <div class="mob-dostavka__uslovia--container-grid">
                  <div className="mob-dostavka__numbers">500</div>
                  <div class="mob-dostavka__uslovia--container-grid-text">
                    Стоимость доставки от пятиста рублей на один адрес в пределах Липецка
                  </div>
                </div>
                <div class="mob-dostavka__uslovia--container-grid">
                  <img src={bike} alt="bike" style={{ width: '35vw', marginBottom: '2vw' }} />
                  <div class="mob-dostavka__uslovia--container-grid-text">
                    В случае доставки продукции из ПВХ за пределы города Липецк стоимость доставки
                    необходимо уточнять у менеджера
                  </div>
                </div>
                <div class="mob-dostavka__uslovia--container-grid">
                  <div className="mob-dostavka__numbers">1</div>
                  <div class="mob-dostavka__uslovia--container-grid-text">
                    Контрольное оповещение происходит за день до назначенной даты доставки
                  </div>
                </div>
                <div class="mob-dostavka__uslovia--container-grid">
                  <img src={coin} alt="coin" style={{ width: '27vw', marginBottom: '2vw' }} />
                  <div class="mob-dostavka__uslovia--container-grid-text">
                    За 1 час перед доставкой водитель оповестит Вас. Повторная доставка продукции
                    является платной в случае, если клиент не смог принять заказ в назначенный день
                  </div>
                </div>
              </div>
            </div>
            <div className="mob-dostavka__uslovia2">
              <h2 className="mob-dostavka__uslovia--h2">Условия доставки</h2>

              <div className="mob-dostavka__uslovia2--container">
                <div className="mob-dostavka__uslovia2--container-element">
                  Для проведения разгрузочных работ клиент обязан предоставить условия для разгрузки
                  изделий: обеспечить подъезд машины к месту разгрузки не более 20 метров, в
                  строении должны присутствовать лестничные пролеты либо деревянные настилы
                  способные выдержать вес не менее 300 кг. При отсутствии, какого либо выше
                  перечисленного условия, сотрудники отдела доставки вправе отказаться от разгрузки
                  изделий до устранения препятствий для разгрузки. 
                </div>
                <div className="mob-dostavka__uslovia2--container-element">
                  Стоимость подъёма продукции зависит от площади изделия, окна площадью до 3,5м²
                  оплачиваются из расчета 100 руб. за этаж, площадью более 3,5 м² оплачиваются из
                  расчета 200 руб за этаж. При наличии грузового лифта (изделие помещается в лифте),
                  оплата производится за первый и последний этажи.
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <Component13 />
      {isContactFormVisible && (
        <div className="modal-overlay">
          <ContactForm onClose={handleModalClose} />
        </div>
      )}
    </>
  );
};
export default Dostavka;
