import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import './ustanovka.css';
import mainImg from './images/main-image.svg';
import icon_bld from './images/icon-bld.svg';
import icon_bin from './images/icon-bin.svg';
import icon_clocks from './images/icon-clocks.svg';
import icon_doc from './images/icon-doc.svg';
import icon_helmet from './images/icon-helmet.svg';
import icon_window from './images/icon-window.svg';
import icon from './images/icon.svg';

import woman from './images/woman.svg';
import drill from './images/drill.svg';
import hammer from './images/hammer.svg';
import termometr from './images/termometr.svg';
import mob_termometr from './images/mob-termometr.svg';
import UpperMenu from '../../Elements/UpperMenu.jsx';
import Component13 from '../../Elements/mainPage/Component13.jsx';
import ContactForm from '../../contact-form/ContactForm.jsx';

const Ustanovka = () => {
  const menuArr = ['О компании', 'История', 'Лицензии', 'Партнерам', 'Поставщикам', 'Документы'];

  const [activePage, setActivePage] = useState('');

  const [isContactFormVisible, setContactFormVisible] = useState(false);

  const handleToggleContactForm = () => {
    setContactFormVisible(!isContactFormVisible);
  };

  const handleModalClose = () => {
    setContactFormVisible(false);
  };

  const [isDesktop, setIsDesktop] = useState(window.innerWidth);
  const handleResize = () => {
    setIsDesktop(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Пластиковые Окна Окна ПВХ</title>
        <meta
          name="description"
          content="Пластиковые окна

          Пластиковые окна Пластиковые окна — это современное и практичное решение для вашего дома или квартиры. Они обладают множеством преимуществ, которые делают их лучшим выбором для остекления любых помещений. Вот некоторые из них:
          
          - Изделия из ПВХ имеют высокую теплоизоляцию и энергоэффективность. Они не пропускают холод и сквозняки, сохраняют тепло внутри и снижают расходы на отопление. Такие окна также защищают от перегрева в жаркое время года, благодаря специальным стеклопакетам с солнцезащитой.
          - Эти конструкции обеспечивают хорошую защиту от шума. Они изолируют внешние звуки и создают тихую и уютную атмосферу в доме. Они также устойчивы к взлому и повреждениям, благодаря качественной фурнитуре и противовзломным системам.
          - Оконные рамы из пластика легко устанавливаются и обслуживаются. Они не нуждаются в покраске или ремонте, не коррозируют и не деформируются. Они легко моются и не теряют свой цвет и блеск со временем. Они также имеют долгий срок службы — до 50 лет и более.
          - Оконные блоки из пластика имеют разнообразный дизайн и подходят для любого интерьера. Вы можете выбрать их любого вида, размера и формы: арочные, эркерные, панорамные, раздвижные, подъемно-раздвижные и другие. Вы также можете выбрать их любого цвета: белые, черные, цветные, ламинированные под дерево или камень.
          - Они подходят для остекления любых помещений: квартир, домов, балконов, лоджий, террас, веранд, детских, коттеджей и других. Они создают комфорт и улучшают микроклимат в помещениях. Они обеспечивают свежий воздух и оптимальную влажность, благодаря системам вентиляции и конденсата. Они также предотвращают появление плесени и грибка, которые могут нанести вред здоровью.
          - Они экологичны и безопасны для окружающей среды. Они не содержат вредных веществ и не выделяют токсичных газов. Они также экономят электроэнергию и снижают выбросы углекислого газа. Они изготавливаются из перерабатываемых материалов и могут быть утилизированы после окончания срока службы.
          Как вы видите, пластиковые окна — это идеальный вариант для вашего дома или квартиры. Они сочетают в себе качество, функциональность, эстетику и экономию. "
        />
        <meta property="og:title" content="Пластиковые Окна Окна ПВХ" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://ok-schtern.ru/" />
        <meta property="og:image" content="URL_ИЗОБРАЖЕНИЯ" />
        <meta
          property="og:description"
          content="Пластиковые окна — это современное и практичное решение для вашего дома или квартиры."
        />
        <meta property="og:site_name" content="SCHTERN" />
        <meta property="og:locale" content="ru_RU" />
      </Helmet>
      <UpperMenu />

      {isDesktop > 480 ? (
        <>
          <div className="ustanovka">
            <h2>Установка пластиковых окон</h2>
            <div className="ustanovka__1">
              <img src={mainImg} alt="main-image_ustanovka" className="ustanovka__main-image" />
              <div className="ustanovka__1--text">
                Длительный срок службы и надёжность пластиковых окон обеспечиваются не только
                благодаря использованию в их производстве высококачественных сертифицированных
                материалов, но и за счёт соблюдения всех строительных норм и соответствующих ГОСТов
                при монтаже (прежде всего ГОСТ 30971-2002 и ГОСТ Р 52749-2007).
              </div>
            </div>
            <div className="ustanovka__2">
              <img src={icon} alt="" />
              <div className="ustanovka__2--text">
                Оформите заявку на услугу, мы свяжемся с вами в ближайшее время и ответим на все
                интересующие вопросы.
              </div>
              <div className="ustanovka__2--link" onClick={handleToggleContactForm}>
                Отправить заявку
              </div>
            </div>

            {/* <div className="ustanovka__nav">
              {menuArr.map((item) => (
                <p
                  onClick={(event) => setActivePage(event.target.textContent)}
                  className={
                    activePage === item ? 'ustanovka__nav--activeTitle' : 'ustanovka__nav--title'
                  }>
                  {item}
                </p>
              ))}
            </div> */}
          </div>
          <div></div>
          <div className="ustanovka__info-container">
            <div className="ustanovka__info-container--grids">
              <div className="ustanovka__info-container--grids-1">
                <img src={woman} alt="" style={{ width: '21vw', marginRight: '8vw' }} />
                <div className="ustanovka__info-container--grids-container">
                  <div className="ustanovka__info-container--grid">
                    <div>
                      <img src={drill} alt="" className="ustanovka__grid-img" />
                      <div className="ustanovka__grid-text">
                        Безусловно, важнейшую роль в том, насколько вы будете удовлетворены новыми
                        окнами, играет мастерство монтажников. Наличие опыта, четкое выполнение всех
                        обязательных этапов монтажа и соблюдение технологии гарантирует отличный
                        результат и отсутствие проблем при дальнейшей эксплуатации окна. Поэтому
                        монтаж по ГОСТ должен выполнять только специалист.
                      </div>
                    </div>
                  </div>
                  <div className="ustanovka__info-container--grid">
                    <div>
                      <img src={hammer} alt="" className="ustanovka__grid-img" />
                      <div className="ustanovka__grid-text">
                        Видимый показатель качественной установки — отсутствие каких бы то ни было
                        зазоров и продуваний в монтажном шве. В конце производится тщательная
                        регулировка фурнитуры и тестирование плотности закрывания оконных створок.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="ustanovka__info-container--zimoy-container">
              <div className="ustanovka__info-container--zimoy-text-container">
                <div className="ustanovka__info-container--zimoy-text-1">
                  Можно ли ставить пластиковые окна зимой? 
                </div>
                <div className="ustanovka__info-container--zimoy-text-2">
                  Даже в зимних условиях наша компания проводит монтаж окон ПВХ по ГОСТу с
                  использованием специальных материалов, рассчитанных на применение при
                  отрицательной температуре, без ущерба для качества установки. При этом в процессе
                  монтажа оконный проём остаётся открытым не более 20 минут, и вымерзания помещения
                  не происходит. 
                </div>
              </div>
              <div className="ustanovka__info-container--zimoy-img">
                <img src={termometr} alt="" />
              </div>
            </div>
            <div className="ustanovka__how-make">
              <h2 className="ustanovka__how-make--h2">
                Как правильно подготовиться к монтажу окон:
              </h2>
              <div className="ustanovka__how-make--grid-container">
                <div className="ustanovka__how-make--grid">
                  <img src={icon_bld} alt="bld" />
                  Если вы живёте в многоквартирном доме, предупредите своих соседей о том, что у вас
                  будет проводиться монтаж окон. Шума перфоратора при монтаже избежать не удастся. 
                </div>
                <div className="ustanovka__how-make--grid">
                  <img src={icon_clocks} alt="clocks" />
                  Согласуйте время доставки готовых окон ПВХ с менеджером и заранее подготовьте
                  место в квартире, где можно будет поместить ваши окна так, чтобы они не мешали вам
                  до приезда монтажной бригады. 
                </div>
                <div className="ustanovka__how-make--grid">
                  <img src={icon_bin} alt="bin" />В процессе монтажа строительный мусор собирается в
                  мешки и выносится на лестничную площадку. Предусмотрите на лестничной клетке место
                  для складирования строительного мусора и своих старых окон. 
                </div>
                <div className="ustanovka__how-make--grid">
                  Освободите пространство от предметов мебели для прохода к оконным проёмам и
                  проведения работ. Наши специалисты проводят монтаж очень аккуратно, тем не менее
                  мы рекомендуем накрыть плёнкой мебель, расположенную в непосредственной близости
                  от оконных проёмов (не накрывайте газетами или тканью, так как они могут
                  деформироваться и сдвигаться, оставляя поверхность незащищённой), и убрать
                  бьющиеся предметы. 
                </div>
                <div className="ustanovka__how-make--grid">
                  <img src={icon_window} alt="window" />
                  Полностью освободите окно, снимите шторы. 
                </div>
                <div className="ustanovka__how-make--grid">
                  <img src={icon_helmet} alt="helmet" />
                  Спланируйте своё время так, чтобы во время приёма и монтажа окон вы или кто-то из
                  ваших родственников могли остаться дома. 
                </div>
                <div className="ustanovka__how-make--grid-single">
                  <img src={icon_doc} alt="doc" />
                  Вывоз строительного мусора можно согласовать индивидуально при заключении
                  договора. 
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="mob-ustanovka">
            <h2>Установка пластиковых окон</h2>
            <div className="mob-ustanovka__1">
              <img src={mainImg} alt="main-image_ustanovka" className="mob-ustanovka__main-image" />
              <div className="mob-ustanovka__1--text">
                Длительный срок службы и надёжность пластиковых окон обеспечиваются не только
                благодаря использованию в их производстве высококачественных сертифицированных
                материалов, но и за счёт соблюдения всех строительных норм и соответствующих ГОСТов
                при монтаже (прежде всего ГОСТ 30971-2002 и ГОСТ Р 52749-2007).
              </div>
            </div>
            <div className="mob-ustanovka__2">
              <img src={icon} alt="" />
              <div className="mob-ustanovka__2--container">
                <div className="mob-ustanovka__2--text">
                  Оформите заявку на услугу, мы свяжемся с вами в ближайшее время и ответим на все
                  интересующие вопросы.
                </div>
                <div className="mob-ustanovka__2--link" onClick={handleToggleContactForm}>
                  Отправить заявку
                </div>
              </div>
            </div>
          </div>

          <div className="mob-ustanovka__info-container">
            <div className="mob-ustanovka__info-container--grids">
              <div className="mob-ustanovka__info-container--grids-1">
                <div className="mob-ustanovka__info-container--grids-container">
                  <div className="mob-ustanovka__info-container--grid">
                    <div>
                      <img src={drill} alt="" className="mob-ustanovka__grid-img" />
                      <div className="mob-ustanovka__grid-text">
                        Безусловно, важнейшую роль в том, насколько вы будете удовлетворены новыми
                        окнами, играет мастерство монтажников. Наличие опыта, четкое выполнение всех
                        обязательных этапов монтажа и соблюдение технологии гарантирует отличный
                        результат и отсутствие проблем при дальнейшей эксплуатации окна. Поэтому
                        монтаж по ГОСТ должен выполнять только специалист.
                      </div>
                    </div>
                  </div>
                  <div className="mob-ustanovka__info-container--grid">
                    <div>
                      <img src={hammer} alt="" className="mob-ustanovka__grid-img" />
                      <div className="mob-ustanovka__grid-text">
                        Видимый показатель качественной установки — отсутствие каких бы то ни было
                        зазоров и продуваний в монтажном шве. В конце производится тщательная
                        регулировка фурнитуры и тестирование плотности закрывания оконных створок.
                      </div>
                    </div>
                  </div>
                  <div className="mob-ustanovka__info-container--grid3">
                    Самостоятельный монтаж тяжелой конструкции без опыта и специальных знаний сложен
                    и трудоемок. Во избежание неприятностей лучше заказать монтаж окон ПВХ у опытных
                    специалистов. Последствия неправильного монтажа могут быть стать неприятными. В
                    случае ошибок при остеклении свойства ПВХ-конструкций по сохранению тепла,
                    защите от шума и пыли могут быть потеряны. Работы, проведенные с ошибками,
                    обернутся появлением конденсата, сквозняков, образованием трещин на откосах,
                    сыростью, ростом грибка. Кроме этого зафиксированная с перекосом рама может
                    создавать избыточное напряжение, выводящее из строя запорные механизмы.
                  </div>
                </div>
              </div>
            </div>

            <div className="mob-ustanovka__info-container--zimoy-container">
              <div className="mob-ustanovka__info-container--zimoy-text-1">
                Можно ли ставить пластиковые окна зимой? 
              </div>
              <div className="mob-ustanovka__info-container--zimoy-text-2">
                Даже в зимних условиях наша компания проводит монтаж окон ПВХ по ГОСТу с
                использованием специальных материалов, рассчитанных на применение при отрицательной
                температуре, без ущерба для качества установки. При этом в процессе монтажа оконный
                проём остаётся открытым не более 20 минут, и вымерзания помещения не происходит. 
              </div>

              <div className="mob-ustanovka__info-container--zimoy-img">
                <img src={mob_termometr} alt="" />
              </div>
            </div>
            <div className="mob-ustanovka__how-make">
              <h2 className="mob-ustanovka__how-make--h2">
                Как правильно подготовиться <br />к монтажу окон:
              </h2>
              <div className="mob-ustanovka__how-make--container">
                <div className="mob-ustanovka__how-make--el">
                  <img src={icon_bld} alt="bld" />
                  Если вы живёте в многоквартирном доме, предупредите своих соседей о том, что у вас
                  будет проводиться монтаж окон. Шума перфоратора при монтаже избежать не удастся. 
                </div>
                <div className="mob-ustanovka__how-make--el">
                  <img src={icon_clocks} alt="clocks" />
                  Согласуйте время доставки готовых окон ПВХ с менеджером и заранее подготовьте
                  место в квартире, где можно будет поместить ваши окна так, чтобы они не мешали вам
                  до приезда монтажной бригады. 
                </div>
                <div className="mob-ustanovka__how-make--el">
                  <img src={icon_bin} alt="bin" />В процессе монтажа строительный мусор собирается в
                  мешки и выносится на лестничную площадку. Предусмотрите на лестничной клетке место
                  для складирования строительного мусора и своих старых окон. 
                </div>
                <div className="mob-ustanovka__how-make--el">
                  Освободите пространство от предметов мебели для прохода к оконным проёмам и
                  проведения работ. Наши специалисты проводят монтаж очень аккуратно, тем не менее
                  мы рекомендуем накрыть плёнкой мебель, расположенную в непосредственной близости
                  от оконных проёмов (не накрывайте газетами или тканью, так как они могут
                  деформироваться и сдвигаться, оставляя поверхность незащищённой), и убрать
                  бьющиеся предметы. 
                </div>
                <div className="mob-ustanovka__how-make--el">
                  <img src={icon_window} alt="window" />
                  Полностью освободите окно, снимите шторы. 
                </div>
                <div className="mob-ustanovka__how-make--el">
                  <img src={icon_helmet} alt="helmet" />
                  Спланируйте своё время так, чтобы во время приёма и монтажа окон вы или кто-то из
                  ваших родственников могли остаться дома. 
                </div>
                <div className="mob-ustanovka__how-make--el">
                  <img src={icon_doc} alt="doc" />
                  Вывоз строительного мусора можно согласовать индивидуально при заключении
                  договора. 
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <Component13 />
      {isContactFormVisible && (
        <div className="modal-overlay">
          <ContactForm onClose={handleModalClose} />
        </div>
      )}
    </>
  );
};
export default Ustanovka;
