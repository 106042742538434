import React, {useEffect, useState} from 'react';
import styles from './index.module.css'
import UpperMenu from '../../Elements/UpperMenu.jsx';
import Component13 from '../../../components/Elements/mainPage/Component13.jsx';
import build from './images/build.svg'
import mobBuild from './images/mobBuild.svg'
import cloud from './images/cloud.svg'
import plane from './images/airplane.svg'
import ProfilesInfo from './ProfilesInfo.jsx';
import profilesArr from './images/technical_information.js'
import ProfileButton from "./ProfileButton";


const Profiles = () => {
    const [activeProfile, setActiveProfile] = useState('58');
    const [activeInformation, setActiveInformation] = useState({});

    const [isDesktop, setIsDesktop] = useState(window.innerWidth);
    const handleResize = () => {
        setIsDesktop(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const obj = profilesArr.find(e => e.name === activeProfile);
        setActiveInformation(obj)
    }, [activeProfile]);



    return (
        <>
            {isDesktop > 480 ?

                <>
                <UpperMenu/>
                <div className={styles.header}>
                    <h2 className={styles.title}>Купить профиль</h2>
        <img src={build} className={styles.build} alt='build'/>
        <img src={cloud} alt="cloud" className={styles.cloud1}/>
        <img src={cloud} alt="cloud" className={styles.cloud2}/>
        <img src={plane} alt='plane' className={styles.plane}/>
        </div>

    <div className={styles.buttonsContainer}>
        {
            profilesArr.map((p) => (
                <div
                    onClick={() => setActiveProfile(p.name)}
                >
                    <ProfileButton
                        name={p.name}
                        active={activeProfile === p.name}
                    />
                </div>
            ))
        }
    </div>
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <ProfilesInfo
            profileInfo={activeInformation}
        />
    </div>
    <Component13/>
</>
    :
    <>
        <UpperMenu/>
        <div className={styles.mobHeader}>
            <h2 className={styles.mobTitle}>Купить профиль</h2>
            <img src={mobBuild} className={styles.mobBuild} alt='build'/>
            <img src={cloud} alt="cloud" className={styles.mobCloud2}/>
            <img src={plane} alt='plane' className={styles.mobPlane}/>
        </div>

        <div className={styles.mobButtonsContainer}>
            {
                profilesArr.map((p) => (
                    <div
                        onClick={() => setActiveProfile(p.name)}
                    >
                        <ProfileButton
                            name={p.name}
                            active={activeProfile === p.name}
                        />
                    </div>
                ))
            }
        </div>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <ProfilesInfo
                profileInfo={activeInformation}
            />
        </div>
        <Component13/>
    </>
            }
</>

    );
};

export default Profiles;
