import React, { useState, startTransition } from 'react';
import {
    Box,
    Typography,
    Card,
    CardMedia,
    CardContent,
    Collapse,
    Grid,
    Paper,
    Stack,
    useTheme,
    useMediaQuery
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Phone from "../../assest/icons/Phone";
import ContactUs from "../../assest/icons/ContactUs";
import Mail from "../../assest/icons/Mail";
import styles from './PlasticWindowBlock.module.css';
import single_sash from './images/1sash.svg';
import double_sash from './images/2sash.svg';
import triple_sash from './images/3sash.svg';
import slide_sash from './images/slide_sash.svg';
import {createTheme, ThemeProvider} from "@mui/material/styles";

const windowTypes = [
    {
        title: 'Одностворчатые окна',
        description: 'Одностворчатые пластиковые окна — это оптимальный выбор для тех, кто ищет практичное и бюджетное решение для небольших помещений. Эти окна идеально подходят для таких мест, как кухни, ванные комнаты и балконы, где пространство ограничено. Одностворчатые окна обеспечивают хорошую теплоизоляцию и звукоизоляцию, что позволяет создать комфортную атмосферу в вашем доме. Благодаря простоте конструкции, они легко открываются и закрываются, что делает их удобными в использовании. Пластиковые окна не требуют особого ухода и обладают высокой стойкостью к внешним воздействиям, что гарантирует долгий срок службы. Выбор одностворчатых окон также позволяет сэкономить на установке и обслуживании, делая их отличным вариантом для дач и загородных домов.',
        image: single_sash,
    },
    {
        title: 'Двустворчатые окна',
        description: 'Двухстворчатые окна представляют собой идеальное сочетание стиля и функциональности, что делает их одним из самых популярных вариантов для жилых помещений. Они обеспечивают отличную вентиляцию и освещение, позволяя одновременно открывать обе створки для создания свежего потока воздуха. Данные окна идеально подходят для спален и гостиных, где важна эстетика, а также возможность регулировать свет и воздух. Двухстворчатые окна могут быть выполнены в различных цветах и отделках, что позволяет легко интегрировать их в любой интерьер. Благодаря высококачественным материалам и современным стеклопакетам, они обеспечивают надежную защиту от холода и шума, что делает ваш дом комфортным и уютным в любое время года. Установка таких окон также повышает стоимость недвижимости, что является дополнительным преимуществом.',
        image: double_sash,
    },
    {
        title: 'Трёхстворчатые окна',
        description: 'Трехстворчатые пластиковые окна становятся всё более популярными среди владельцев домов и квартир благодаря своей способности обеспечивать максимальную освещенность и вентилируемость. Они идеально подходят для больших помещений, таких как гостиные или студии, где важно создать светлую и уютную атмосферу. Эти окна состоят из трех створок, что позволяет открывать любое количество из них, обеспечивая тем самым гибкость в использовании пространства. При открытых створках вы сможете наслаждаться свежим воздухом, а при закрытых окна обеспечат отличную звуко- и теплоизоляцию. Современные трехстворчатые окна могут быть оборудованы высококачественными энергосберегающими стеклопакетами, которые значительно улучшают теплоизоляционные свойства, что особенно актуально в зимний период. Такой подход помогает значительно снизить затраты на отопление и создает комфортный микроклимат в доме. Кроме того, они могут быть выполнены в различных стилях и цветах, что позволяет легко вписать их в любой интерьер и подчеркнуть уникальность вашего жилья.',
        image: triple_sash,
    },
    {
        title: 'Раздвижные окна',
        description: 'Раздвижные пластиковые окна — это элегантное и практичное решение, позволяющее максимально использовать пространство. Они открываются без лишних усилий, что делает их отличным выбором для террас, веранд и небольших балконов. Раздвижные окна позволяют легко выходить на улицу и наслаждаться свежим воздухом, при этом не занимая полезное пространство в помещении. Такой вариант идеально подходит для современных интерьеров и помогает создать ощущение легкости и свободы. Раздвижные окна могут быть выполнены с использованием высококачественных стеклопакетов, которые обеспечивают отличную теплоизоляцию и защиту от шума. Их можно устанавливать как в новых, так и в уже существующих домах, добавляя функциональности и стиля в любое пространство. Эти окна доступны в различных вариантах отделки, что позволяет выбрать идеальное решение для вашего дома.',
        image: slide_sash,
    },
];

const contactInfo = [
    { label: 'Телефон', value: '8-800-550-58-78', icon: <Phone color={'#222222'} /> },
    { label: 'Email', value: 'shop@ok-schtern.ru', icon: <Mail color={'#222222'} /> },
    { label: 'Форма обратной связи', value: 'Заполнить', icon: <ContactUs color={'#222222'} /> },
];

const PlasticWindowBlock = () => {
    const [expandedIndex, setExpandedIndex] = useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleExpandClick = (index) => {
        startTransition(() => {
            setExpandedIndex(expandedIndex === index ? null : index);
        });
    };

    const th = createTheme({
        typography: {
            h5: {
                fontSize: '24px',
                '@media (max-width:600px)': {
                    fontSize: '20px',
                },
            },
            h6: {
                fontSize: '20px',
                '@media (max-width:600px)': {
                    fontSize: '18px',
                },
            },
            subtitle1: {
                fontSize: '18px',
                '@media (max-width:600px)': {
                    fontSize: '16px',
                },
            },
            body1: {
                fontSize: '16px',
                '@media (max-width:600px)': {
                    fontSize: '14px',
                },
            },
            body2: {
                fontSize: '14px',
                '@media (max-width:600px)': {
                    fontSize: '12px',
                },
            },
        },
    });

    return (
        <ThemeProvider theme={th}>


        <Box sx={{ padding: 3 }}>
            <Grid container spacing={2}>
                {isMobile &&
                    <Grid item xs={12} md={3}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', textAlign: 'left', marginBottom: 1 }}>
                            Свяжитесь с нами
                        </Typography>
                        <Paper sx={{ padding: 2, borderRadius: 4 }}>
                            <Stack gap={2}>
                                {contactInfo.map((info, index) => (
                                    <Box key={index}>
                                        <Stack direction={'row'} gap={2} sx={{ alignItems: 'center' }}>
                                            <Stack>{info.icon}</Stack>
                                            <Stack gap={0.5}>
                                                <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'Unbounded' }}>{info.label}:</Typography>
                                                {info.label === 'Телефон' ? (
                                                    <Typography
                                                        variant="body2" color="text.secondary" sx={{ fontFamily: 'Unbounded' }}
                                                        onClick={() => window.open(`tel:${info.value}`)}
                                                    >
                                                        {info.value}
                                                    </Typography>
                                                ) : info.label === 'Email' ? (
                                                    <Typography
                                                        variant="body2" color="text.secondary" sx={{ fontFamily: 'Unbounded' }}
                                                        onClick={() => window.open(`mailto:${info.value}`)}
                                                    >
                                                        {info.value}
                                                    </Typography>
                                                ) : (
                                                    <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'Unbounded' }}>
                                                        {info.value}
                                                    </Typography>
                                                )}
                                            </Stack>
                                        </Stack>
                                    </Box>
                                ))}
                            </Stack>
                        </Paper>
                    </Grid>
                }
                <Grid item xs={12} md={9}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', textAlign: 'left', marginBottom: 1 }}>
                        Типы пластиковых окон
                    </Typography>
                    <Paper sx={{ borderRadius: 4 }}>
                        <Stack direction={{ xs: 'column', md: 'row' }} sx={{ alignItems: 'stretch', minHeight: '100%' }}>
                            <Box sx={{ width: { xs: '100%', md: '50%' }, display: 'flex', alignItems: 'center', justifyContent: 'center', p: 2 }}>
                                <CardMedia
                                    component="img"
                                    image={windowTypes[0].image}
                                    alt="Одностворчатые окна"
                                    sx={{
                                        borderRadius: 4,
                                        maxHeight: 400,
                                        maxWidth: '100%',
                                        objectFit: 'contain',
                                    }}
                                />
                            </Box>
                            <Stack sx={{ padding: 2, width: { xs: '100%', md: '50%' }, justifyContent: 'center' }} gap={1}>
                                {
                                    !isMobile ?
                                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                            {windowTypes[0].title}
                                        </Typography>
                                        :
                                        <Typography gutterBottom variant="h6" component="div" sx={{ fontFamily: 'Unbounded' }}>
                                            {windowTypes[0].title}
                                        </Typography>
                                }
                                <Typography variant="body1" className={styles.title_text} sx={{ textAlign: 'justify', wordBreak: 'break-word' }}>
                                    {windowTypes[0].description}
                                </Typography>
                                <Typography variant="body2" sx={{ textAlign: 'justify', wordBreak: 'break-word' }}>
                                    {windowTypes[0].usage}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Paper>
                </Grid>

                {!isMobile &&
                    <Grid item xs={12} md={3}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', textAlign: 'left', marginBottom: 1 }}>
                            Свяжитесь с нами
                        </Typography>
                        <Paper sx={{ padding: 2, borderRadius: 4 }}>
                            <Stack gap={2}>
                                {contactInfo.map((info, index) => (
                                    <Box key={index}>
                                        <Stack direction={'row'} gap={2} sx={{ alignItems: 'center' }}>
                                            <Stack>{info.icon}</Stack>
                                            <Stack gap={0.5}>
                                                <Typography variant="body1" color="text.secondary" sx={{ fontFamily: 'Unbounded' }}>{info.label}:</Typography>
                                                {info.label === 'Телефон' ? (
                                                    <Typography
                                                        variant="body1"
                                                        sx={{ color: '#222222', cursor: 'pointer' , fontFamily: 'Unbounded'}}
                                                        onClick={() => window.open(`tel:${info.value}`)}
                                                    >
                                                        {info.value}
                                                    </Typography>
                                                ) : info.label === 'Email' ? (
                                                    <Typography
                                                        variant="body1"
                                                        sx={{ color: '#222222', cursor: 'pointer' , fontFamily: 'Unbounded'}}
                                                        onClick={() => window.open(`mailto:${info.value}`)}
                                                    >
                                                        {info.value}
                                                    </Typography>
                                                ) : (
                                                    <Typography
                                                        variant="body1"
                                                        sx={{ color: '#222222', cursor: 'pointer' , fontFamily: 'Unbounded'}}>
                                                        {info.value}
                                                    </Typography>
                                                )}
                                            </Stack>
                                        </Stack>
                                    </Box>
                                ))}
                            </Stack>
                        </Paper>
                    </Grid>
                }

                {windowTypes.slice(1).map((window, index) => (
                    <Grid item xs={12} md={4} key={window.title}>
                        <Card sx={{ borderRadius: 4, padding: 2 }}>
                            <CardMedia
                                component="img"
                                height="200"
                                image={window.image}
                                alt={window.title}
                                sx={{
                                    borderRadius: 4,
                                    maxHeight: 400,
                                    maxWidth: '100%',
                                    objectFit: 'contain',
                                }}
                            />
                            <CardContent sx={{ padding: 2 }}>
                                <Typography gutterBottom variant="h6" component="div" sx={{ fontFamily: 'Unbounded' }}>
                                    {window.title}
                                </Typography>
                                <Stack sx={{ cursor: "pointer", width: "max-content", alignItems: "center" }} direction={'row'} onClick={() => handleExpandClick(index)}>
                                    <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'Unbounded' }}>
                                        {expandedIndex === index ? 'Свернуть' : 'Подробнее'}
                                    </Typography>
                                </Stack>

                                <Collapse in={expandedIndex === index} timeout="auto" unmountOnExit>
                                    <Box sx={{ paddingTop: 2 }}>
                                        <Typography variant="body2" sx={{ fontFamily: 'Unbounded', textAlign: 'justify', wordBreak: 'break-word' }}>
                                            {window.description}
                                        </Typography>
                                    </Box>
                                </Collapse>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
        </ThemeProvider>
    );
};

export default PlasticWindowBlock;
