import React from 'react';

const Mail = ({
    color = 'white',
              }) => {
    return (
        <svg width="39" height="31" viewBox="0 0 39 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.65625 0.5C1.6377 0.5 0 2.17969 0 4.25C0 5.42969 0.54082 6.53906 1.4625 7.25L18.0375 20C18.9059 20.6641 20.0941 20.6641 20.9625 20L37.5375 7.25C38.4592 6.53906 39 5.42969 39 4.25C39 2.17969 37.3623 0.5 35.3438 0.5H3.65625ZM0 9.25V25.5C0 28.2578 2.18613 30.5 4.875 30.5H34.125C36.8139 30.5 39 28.2578 39 25.5V9.25L22.425 22C20.6883 23.3359 18.3117 23.3359 16.575 22L0 9.25Z"
                fill={color}/>
        </svg>

    );
};

export default Mail;
