import React from 'react';
import okno1 from '../assest/svgEl/oknoMenu1.svg'
import okno2 from '../assest/svgEl/oknoMenu2.svg'

const Dilleram = () => {

    return (
        <>

       <p>
        Диллерам
       </p>
       <img src={okno1}></img>
       <img src={okno2  }></img>
        </>

       
    )
}

export default Dilleram;