import React, { useState, useEffect } from 'react';
import '../../../css/mainPage/component2.css';
import window1 from '../../../assest/svgEl/main/cat-window1.svg';
import window2 from '../../../assest/svgEl/main/cat-window2.svg';
import window3 from '../../../assest/svgEl/main/cat-window3.svg';
import window11 from '../../../assest/svgEl/main/chng-window1.svg';
import window21 from '../../../assest/svgEl/main/chng-window2.svg';
import window31 from '../../../assest/svgEl/main/chng-window3.svg';
import { Link } from 'react-router-dom';

const Component2 = () => {
  const [images, setImages] = useState([
    { original: window1, hover: window11, link: '/plastikovye-okna' },
    { original: window2, hover: window21, link: '/balkony' },
    { original: window3, hover: window31, link: '/aksessuary' }
  ]);

  const [isDesktop, setIsDesktop] = useState(window.innerWidth);
  const handleResize = () => {
    setIsDesktop(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleImageHover = (index, isHovering) => {
    const updatedImages = [...images];
    updatedImages[index].current = isHovering
      ? updatedImages[index].hover
      : updatedImages[index].original;
    setImages(updatedImages);
  };

  return (
    <div>
      {isDesktop > 480 ? (
        <div className="background-container-catalog">
          <div className="background-top" />
          <div className="background-stripe" />
          <div className="background-content">
            <h2 className="catalog-title">Каталог</h2>

            <div className="image-container">
              {images.map((image, index) => (
                <Link to={image.link}>
                  <img
                    key={index}
                    src={image.current || image.original}
                    alt={`Catalog Item ${index + 1}`}
                    className="catalog-image"
                    onMouseEnter={() => handleImageHover(index, true)}
                    onMouseLeave={() => handleImageHover(index, false)}
                  />
                </Link>
              ))}
            </div>
          </div>

          <div className="background-bottom">
            {Array.from({ length: 9 }, (_, index) => (
              <div key={index} className="decorative-rectangle" />
            ))}
          </div>
        </div>
      ) : (
        <div className="background-container-catalog">
          <div className="background-top" />
          <div className="background-stripe" />
          <div className="background-content">
            <h2 className="catalog-title">Каталог</h2>

            <div className="image-container1-c2">
              <img src={window1} alt="window1" className="window1" />
              <img src={window2} alt="window2" className="window2" />
            </div>
            <div className="image-container2-c2">
              <img src={window3} alt="window3" className="window3" />
            </div>
          </div>

          <div className="background-bottom">
            {Array.from({ length: 9 }, (_, index) => (
              <div key={index} className="decorative-rectangle" />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Component2;
