import React, { useState } from 'react';
import { Box, Typography, Card, CardMedia, CardContent, Collapse, Grid, Paper, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import profile58 from './images/58frame.png';
import profile70 from './images/70frame.png';
import profile82 from './images/82frame.png';

const windowTypes = [
    {
        title: 'Определение ширины оконного профиля',
        description: 'Ширина оконного профиля является важным параметром, который влияет на общие характеристики окна. Этот показатель обозначает толщину рамы и может варьироваться в зависимости от конструкции и материалов. В большинстве современных окон профиль варьируется от 58 до 90 мм. Определение подходящей ширины профиля зависит от функциональных требований, таких как тепло- и звукоизоляция, прочность и долговечность окна. Широкий профиль способен улучшить характеристики окна, но важно учитывать баланс между его размером и внешним видом окна.',
        image: profile58,
    },
    {
        title: 'Влияние ширины профля на теплоизоляцию',
        description: 'Ширина оконного профиля непосредственно влияет на его теплоизоляционные свойства. Чем шире профиль, тем больше пространство для размещения дополнительных изоляционных камер и материалов, которые помогают снижать теплопотери. Например, профили шириной 70 мм и более часто включают три и более камеры, что улучшает сопротивление теплопередаче. Широкие профили также позволяют использовать более энергоэффективные стеклопакеты, что увеличивает общий коэффициент теплоизоляции окна.',
        image: profile70,
    },
    {
        title: 'Влияние ширины профля на звукоизоляцию',
        description: 'Ширина профиля оказывает значительное влияние на звукоизоляцию окна. Более широкий профиль позволяет установить многокамерные системы и использовать дополнительные уплотнители, которые снижают уровень проникающего шума. Чем шире рама и больше камер внутри, тем лучше окно справляется с шумами. Профили шириной 80 мм и более могут значительно улучшить звуковую защиту, особенно в сочетании с многослойными стеклопакетами.',
        image: profile82,
    }
];



const theme = createTheme({
    typography: {
        h5: {
            fontSize: '24px',
            '@media (max-width:600px)': {
                fontSize: '20px',
            },
        },
        h6: {
            fontSize: '20px',
            '@media (max-width:600px)': {
                fontSize: '18px',
            },
        },
        subtitle1: {
            fontSize: '18px',
            '@media (max-width:600px)': {
                fontSize: '16px',
            },
        },
        body1: {
            fontSize: '16px',
            '@media (max-width:600px)': {
                fontSize: '14px',
            },
        },
        body2: {
            fontSize: '14px',
            '@media (max-width:600px)': {
                fontSize: '12px',
            },
        },
    },
});

const ProfileWidth = () => {
    const [expandedIndex, setExpandedIndex] = useState(null);

    const handleExpandClick = (index: any) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ padding: 3}}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', textAlign: 'left', fontFamily: 'Unbounded', marginBottom: 1 }}>
                    Ширина оконного профиля и её влияние
                </Typography>
                <Grid container spacing={2} sx={{ flexWrap: 'wrap' }}>
                    {windowTypes.map((window, index) => (
                        <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                            <Card sx={{ borderRadius: 4 }}>
                                {/* Содержимое карточки */}
                                <CardMedia
                                    component="img"
                                    height="200"
                                    image={window.image}
                                    alt={window.title}
                                    sx={{
                                        borderRadius: 4,
                                        maxHeight: 500,
                                        padding: 3,
                                        maxWidth: '100%',
                                        objectFit: 'contain',
                                    }}
                                />
                                <CardContent sx={{ padding: 2 }}>
                                    <Typography gutterBottom variant="h6" component="div" sx={{ fontFamily: 'Unbounded' }}>
                                        {window.title}
                                    </Typography>
                                    <Stack sx={{ cursor: "pointer", width: "max-content", alignItems: "center" }} direction={'row'} onClick={() => handleExpandClick(index)}>
                                        <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'Unbounded' }}>
                                            {expandedIndex === index ? 'Свернуть' : 'Подробнее'}
                                        </Typography>
                                    </Stack>
                                    <Collapse in={expandedIndex === index} timeout="auto" unmountOnExit>
                                        <Box sx={{ paddingTop: 2 }}>
                                            <Typography variant="body2" sx={{ fontFamily: 'Unbounded', textAlign: 'justify', wordBreak: 'break-word' }}>
                                                {window.description}
                                            </Typography>
                                        </Box>
                                    </Collapse>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

            </Box>
        </ThemeProvider>
    );
};

export default ProfileWidth;
