import React, { useState, useEffect } from 'react';
import '../../../css/mainPage/component5.css'; // Импорт соответствующего файла стилей
import Slider from 'react-slick';

import window82 from '../../../assest/photo/windows/window82.JPG';
import window70 from '../../../assest/photo/windows/window70.JPG';
import window58 from '../../../assest/photo/windows/window58.JPG';
import door from '../../../assest/photo/windows/door.png';

const Component5 = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const [isDesktop, setIsDesktop] = useState(window.innerWidth);
  const handleResize = () => {
    setIsDesktop(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [sliderValue, setSliderValue] = useState(50000); // Начальное значение ползунка
  const handleSliderChange = (event) => {
    setSliderValue(parseInt(event.target.value, 10));
  };
  const [selectedValue, setSelectedValue] = useState('1');
  const options = Array.from({ length: 12 }, (_, index) => (index + 1).toString());

  const scaledValue = sliderValue / 1000;

  const dynamicBackgroundColor = `linear-gradient(to right, #fff 0%, #fff ${scaledValue}%, #87DFEE ${scaledValue}%, #87DFEE 100%)`;
  const formatValueWithCommas = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  const costPerMonth = sliderValue / selectedValue;

  return (
    <div className="component5">
      {isDesktop > 480 ? (
        <div className="content-c5">
          <div className="pop-mod-c5">Популярные модели</div>
          <div className="catalog-c5">Каталог</div>
          <div className="okna-c5">
            <div className="okna-blok-c5">
              <div className="okna-blok-frame-c5">
                <img src={window58} alt="window58" className="model-window" />
              </div>
              <p className="details-c5-1">Двухсторчатое окно</p>
              <p className="details-c5">Размер: 1000 x 1200</p>
              <p className="details-c5">Профиль: Schtern 58</p>
              <div className="price-c5">
                <div className="price-more-c5">
                  <a
                    href="https://добрострой.рф/products/okno-plastikovoe-1-2kh1-0m-povorotno-otkidnoe-bez-setki/"
                    target="_blank"
                    rel="noreferrer">
                    Подробнее
                  </a>
                </div>
              </div>
            </div>
            <div className="okna-blok-c5">
              <div className="okna-blok-frame-c5">
                <img src={door} alt="door" className="model-window" />
              </div>
              <p className="details-c5-1">Дверь пластиковая</p>
              <p className="details-c5">Размер: 700 x 2100</p>
              <p className="details-c5">Профиль: Schtern 58</p>
              <div className="price-c5">
                <div className="price-more-c5">
                  {' '}
                  <a
                    href="https://добрострой.рф/products/dver-plastikovaya-2-1kh0-7m-levaya/"
                    target="_blank"
                    rel="noreferrer">
                    Подробнее
                  </a>
                </div>
              </div>
            </div>
            <div className="okna-blok-c5">
              <div className="okna-blok-frame-c5">
                <img src={window58} alt="window58" className="model-window" />
              </div>
              <p className="details-c5-1">Трехстворчатое окно</p>
              <p className="details-c5">Размер: 2100 x 1400</p>
              <p className="details-c5">Профиль: Schtern 58</p>
              <div className="price-c5">
                <div className="price-more-c5">
                  <a
                    href="https://добрострой.рф/products/okno-pvkh-140kh210sm-3-stv-g-po-g-bez-setki/"
                    target="_blank"
                    rel="noreferrer">
                    Подробнее
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Slider {...settings} className="slider-c5">
          <div className="frame-ex">
            <div className="frame-container">
              <div className="okna-blok-c5">
                <div className="okna-blok-frame-c5">
                  {' '}
                  <img src={window82} alt="window82" className="model-window" />
                </div>
                <p className="details-c5-1">Двухсторчатое окно</p>
                <p className="details-c5">Размер: 800 x 1600</p>
                <p className="details-c5">Профиль: Schtern 82</p>
                <div className="price-c5">
                  <div className="price-price-c5">5220Р</div>
                  <div className="price-more-c5">Подробнее</div>
                </div>
              </div>
            </div>
          </div>
          <div className="frame-ex">
            <div className="frame-container">
              <div className="okna-blok-c5">
                <div className="okna-blok-frame-c5">
                  {' '}
                  <img src={window70} alt="window70" className="model-window" />
                </div>
                <p className="details-c5-1">Двухсторчатое окно</p>
                <p className="details-c5">Размер: 800 x 1600</p>
                <p className="details-c5">Профиль: Schtern 82</p>
                <div className="price-c5">
                  <div className="price-price-c5">5220Р</div>
                  <div className="price-more-c5">Подробнее</div>
                </div>
              </div>
            </div>
          </div>

          <div className="frame-ex">
            <div className="frame-container">
              <div className="okna-blok-c5">
                <div className="okna-blok-frame-c5">
                  {' '}
                  <img src={window58} alt="window58" className="model-window" />
                </div>
                <p className="details-c5-1">Двухсторчатое окно</p>
                <p className="details-c5">Размер: 800 x 1600</p>
                <p className="details-c5">Профиль: Schtern 82</p>
                <div className="price-c5">
                  <div className="price-price-c5">5220Р</div>
                  <div className="price-more-c5">Подробнее</div>
                </div>
              </div>
            </div>
          </div>

          {/* Добавьте дополнительные слайды по необходимости */}
        </Slider>
      )}

      <div className="credit-c5">
        <div className="oplata-potom-c5">Окна сейчас - оплата потом</div>

        <div className="container-с5">
          <div className="square-с5">
            <span className="text-с5">0</span>
            <p className="label-с5">Первоначальный</p>
            <p className="label-с5">взнос</p>
          </div>
          <div className="square-с5">
            <span className="text-с5">0</span>
            <p className="label-с5">%переплат</p>
          </div>
          <div className="square-с5">
            <span className="text-с5">3</span>
            <p className="label-с5">Минуты</p>
            <p className="label-с5">срок одобрения</p>
          </div>
          <div className="square-с5">
            <span className="text-с5">12</span>
            <p className="label-с5">Месяцев</p>
            <p className="label-с5">максимальный срок</p>
          </div>
        </div>

        {isDesktop > 480 ? (
          <div className="calculation-c5">
            <div className="cost-c5">
              <p className="cost-price-c5">Стоимость:</p>
              <p className="cost-term-c5">Срок:</p>

              <div className="dropdown">
                <select
                  value={selectedValue}
                  onChange={(e) => setSelectedValue(e.target.value)}
                  className="dropdown-select">
                  {options.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className="slider-value">{formatValueWithCommas(sliderValue)}</div>
              <input
                type="range"
                min="0"
                max="100000"
                step="1000"
                value={sliderValue}
                onChange={handleSliderChange}
                style={{ background: dynamicBackgroundColor }} // Применяем динамический цвет
              />
            </div>
            <div className="cost-month-c5">
              <div className="cost-per-month-c5">
                {costPerMonth === 100000
                  ? formatValueWithCommas(costPerMonth).slice(0, 7)
                  : costPerMonth > 9999
                  ? formatValueWithCommas(costPerMonth).slice(0, 6)
                  : formatValueWithCommas(costPerMonth).slice(0, 5)}{' '}
                руб./месяц
              </div>
              <div className="get-consult-c5">Получить консультацию</div>

              <div></div>
            </div>
          </div>
        ) : (
          <div className="calculation-c5">
            <div className="cost-c5">
              <p className="cost-price-c5">Стоимость:</p>

              <div className="slider-value">{formatValueWithCommas(sliderValue)}</div>
              <input
                type="range"
                min="0"
                max="100000"
                step="1000"
                value={sliderValue}
                onChange={handleSliderChange}
                style={{ background: dynamicBackgroundColor }} // Применяем динамический цвет
              />
            </div>
            <div className="cost-month-c5">
              <p className="cost-term-c5">Срок, мес.:</p>
              <div className="dropdown">
                <select
                  value={selectedValue}
                  onChange={(e) => setSelectedValue(e.target.value)}
                  className="dropdown-select">
                  {options.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className="cost-per-month-c5">
                {costPerMonth === 100000
                  ? formatValueWithCommas(costPerMonth).slice(0, 7)
                  : costPerMonth > 9999
                  ? formatValueWithCommas(costPerMonth).slice(0, 6)
                  : formatValueWithCommas(costPerMonth).slice(0, 5)}{' '}
                руб./месяц
              </div>
              <div className="get-consult-c5">Получить консультацию</div>

              <div></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Component5;
