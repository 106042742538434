import React, { useState, useEffect } from 'react';
import '../../../css/mainPage/component12.css';
import license from '../../../assest/licences/l1.jpeg';
import { Link } from 'react-router-dom';

import Slider from 'react-slick';

const Component12 = () => {
  const [currentSlide1, setCurrentSlide1] = useState(0);
  const [currentSlide2, setCurrentSlide2] = useState(0);

  const slides1 = [
    { text: 'Сертификат соответствия', img: license },
    { text: 'Свидетельство о государственной регистрации', img: license },
    { text: 'Сертификат пожарной безопасности', img: license }
    // Добавьте текст для других слайдов
  ];

  const slides2 = [
    { text: 'Лицензия на проектирование зданий', img: license },
    { text: 'Лицензия на проведение экспертизы', img: license },
    { text: 'Лицензия на техническое обслуживание', img: license }
    // Добавьте текст для других слайдов
  ];

  const settings1 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current) => setCurrentSlide1(current)
  };

  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current) => setCurrentSlide2(current)
  };

  const [isDesktop, setIsDesktop] = useState(window.innerWidth);
  const handleResize = () => {
    setIsDesktop(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="component12">
      {isDesktop > 480 ? (
        <>
          <div className="title-c12">Сертификаты и Лицензии</div>
          <div className="licenses-box-c12">
            <Link to="/o-nas">
              <div className="all-docs-c12">Вся документация</div>
            </Link>
            <div className="c12__license-container">
              <div className="license-box-c12">
                <div className="img-c12">
                  <img src={license} alt="Лицензия" />
                </div>
                <p className="text-c12">Сертификат соответствия</p>
              </div>
              <div className="license-box-c12">
                <div className="img-c12">
                  <img src={license} alt="Лицензия" />
                </div>
                <p className="text-c12">Сертификат пожарной безопасности</p>
              </div>
              <div className="license-box-c12">
                <div className="img-c12">
                  {' '}
                  <img src={license} alt="Лицензия" />
                </div>
                <p className="text-c12">Сертификат соответствия ГОСТ</p>
              </div>
              <div className="license-box-c12">
                <div className="img-c12">
                  {' '}
                  <img src={license} alt="Лицензия" />
                </div>
                <p className="text-c12">Лицензия на проектирование зданий</p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="title-c12">Сертификаты и Лицензии</div>
          <div className="box-c121">
            <Slider {...settings1} className="slider-c12">
              {slides1.map((slide, index) => (
                <div key={index} className="slider-container-c12">
                  <div className="slider-img-c12">
                    <img src={slide.img} alt={slide.text} />
                  </div>
                </div>
              ))}
            </Slider>
            <div className="text-container-с12">{slides1[currentSlide1].text}</div>
          </div>
          <div className="box-c122">
            <Slider {...settings2} className="slider-c12">
              {slides2.map((slide, index) => (
                <div key={index} className="slider-container-c12">
                  <div className="slider-img-c12">
                    <img src={slide.img} alt={slide.text} />
                  </div>
                </div>
              ))}
            </Slider>
            <div className="text-container-с12" style={{ color: '#fff' }}>
              {slides2[currentSlide2].text}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Component12;
