import React, {useEffect, useState} from 'react';
import ReactPlayer from 'react-player';
import play from './youtube_component/play_button.svg';
import back from './youtube_component/youtube_back.svg';
import back_mob from './youtube_component/youtube_back_mob.svg';

const Component14 = () => {
    const [playing, setPlaying] = useState(false);

    const [isDesktop, setIsDesktop] = useState(window.innerWidth);
    const handleResize = () => {
        setIsDesktop(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handlePlayButtonClick = () => {
        setPlaying(true);
    };

    return (
        <>
            {isDesktop > 480 ?
                <div style={{
                    width: '100vw',
                    height: '55vw',
                    backgroundColor: '#FFF7F4',
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}>
                    <h2 style={{
                        fontFamily: 'Unbounded',
                        fontSize: '1.5vw',
                        color: '#161E54',
                        display: 'flex',
                        marginBottom: '2vw',
                        marginLeft: '7vw'
                    }}>
                <span style={{
                    color: '#EA5455',
                }}>Youtube видео - </span>
                        <span style={{
                            color: '#161E54',
                        }}> &nbsp; Кликай и узнай о нас больше</span>
                    </h2>
                    <div style={{
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        {!playing ? (
                            <>
                                <img src={back} alt="back" style={{
                                    width: '70vw',
                                }}/>
                                <img
                                    src={play}
                                    alt="play button"
                                    style={{
                                        position: 'absolute',
                                        width: '10vw',
                                        cursor: 'pointer',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        transition: 'transform 0.2s ease-in-out'
                                    }}
                                    onClick={handlePlayButtonClick}
                                    onMouseDown={(e) => e.currentTarget.style.transform = 'translate(-50%, -50%) scale(0.9)'}
                                    onMouseUp={(e) => e.currentTarget.style.transform = 'translate(-50%, -50%) scale(1)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'translate(-50%, -50%) scale(1)'}
                                />
                            </>
                        ) : (
                            <div style={{
                                width: '70vw',
                                overflow: 'hidden',
                                borderRadius: '0.5rem'
                            }}>
                                <ReactPlayer
                                    url="https://www.youtube.com/watch?v=8q4uoKcJBWE&t=17s"
                                    playing={playing}
                                    controls
                                    width="100%"
                                    onEnded={() => setPlaying(false)}
                                />
                            </div>
                        )}
                    </div>
                </div>
                :
                <div style={{
                    width: '100vw',
                    height: '120vw',
                    backgroundColor: '#FFF7F4',
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}>
                    <h2 style={{
                        fontFamily: 'Unbounded',
                        fontSize: '4vw',
                        color: '#161E54',
                        display: 'flex',
                        marginBottom: '2vw',
                        marginLeft: '7vw',
                        flexDirection: 'column'
                    }}>
                <div style={{
                    color: '#EA5455',
                }}>Youtube видео - </div>
                        <div style={{
                            color: '#161E54',
                        }}>Кликай и узнай о нас больше</div>
                    </h2>
                    <div style={{
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        {!playing ? (
                            <>
                                <img src={back_mob} alt="back" style={{
                                    width: '94vw',
                                }}/>
                                <img
                                    src={play}
                                    alt="play button"
                                    style={{
                                        position: 'absolute',
                                        width: '30vw',
                                        cursor: 'pointer',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        transition: 'transform 0.2s ease-in-out'
                                    }}
                                    onClick={handlePlayButtonClick}
                                    onMouseDown={(e) => e.currentTarget.style.transform = 'translate(-50%, -50%) scale(0.9)'}
                                    onMouseUp={(e) => e.currentTarget.style.transform = 'translate(-50%, -50%) scale(1)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'translate(-50%, -50%) scale(1)'}
                                />
                            </>
                        ) : (
                            <div style={{
                                width: '94vw',
                                overflow: 'hidden',
                                borderRadius: '0.5rem'
                            }}>
                                <ReactPlayer
                                    url="https://www.youtube.com/watch?v=8q4uoKcJBWE&t=17s"
                                    playing={playing}
                                    controls
                                    width="100%"
                                    onEnded={() => setPlaying(false)}
                                />
                            </div>
                        )}
                    </div>
                </div>}
        </>

    );
};

export default Component14;
