import React, {useEffect, useState} from 'react';
import styles from './profile_info.module.css'

const ProfilesInfo = ({
    profileInfo
}) => {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth);
    const handleResize = () => {
        setIsDesktop(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>{
            isDesktop > 480 ?
                <div className={styles.main}>
                    <div className={styles.imageContainer}>
                        <img src={profileInfo.img} alt="profile" style={{width: '25vw'}}/>
                    </div>
                    <div className={styles.tech}>
                        <div className={styles.title}>
                            <span>Технические характеристики профиля</span>
                        </div>
                        <div className={styles.description}>
                            <div className={styles.techRow}>
                                <span className={styles.elName}>Монтажная ширина профиля</span>
                                <span className={styles.elDescription}>{profileInfo.profileWeight}</span>
                            </div>
                            <div className={styles.techRow}>
                                <span className={styles.elName}>Количество камер</span>
                                <span className={styles.elDescription}>{profileInfo.chambersCount}</span>
                            </div>
                            <div className={styles.techRow}>
                                <span className={styles.elName}>Тип по толщине стенок</span>
                                <span className={styles.elDescription}>{profileInfo.typeByWallThickness}</span>
                            </div>
                            <div className={styles.techRow}>
                                <span className={styles.elName}>Ширина стеклопакета</span>
                                <span className={styles.elDescription}>{profileInfo.glazingWidth}</span>
                            </div>
                            <div className={styles.techRow}>
                                <span className={styles.elName}>Удаленность фурнитурного паза</span>
                                <span className={styles.elDescription}>{profileInfo.distanceOfTheHardwareGroove}</span>
                            </div>
                            <div className={styles.techRow}>
                                <span className={styles.elName}>Высота рамы</span>
                                <span className={styles.elDescription}>{profileInfo.frameHeight}</span>
                            </div>
                            <div className={styles.techRow}>
                                <span className={styles.elName}>Высота створки</span>
                                <span className={styles.elDescription}>{profileInfo.sashHeight}</span>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className={styles.mobMain}>
                    <div className={styles.mobImageContainer}>
                        <img src={profileInfo.img} alt="profile" style={{width: '90vw'}}/>
                    </div>
                    <div className={styles.mobTech}>
                        <div className={styles.mobTitle}>
                            <span>Технические характеристики профиля</span>
                        </div>
                        <div className={styles.mobDescription}>
                            <div className={styles.mobTechRow}>
                                <span className={styles.mobElName}>Монтажная ширина профиля</span>
                                <span className={styles.mobElDescription}>{profileInfo.profileWeight}</span>
                            </div>
                            <div className={styles.mobTechRow}>
                                <span className={styles.mobElName}>Количество камер</span>
                                <span className={styles.mobElDescription}>{profileInfo.chambersCount}</span>
                            </div>
                            <div className={styles.mobTechRow}>
                                <span className={styles.mobElName}>Тип по толщине стенок</span>
                                <span className={styles.mobElDescription}>{profileInfo.typeByWallThickness}</span>
                            </div>
                            <div className={styles.mobTechRow}>
                                <span className={styles.mobElName}>Ширина стеклопакета</span>
                                <span className={styles.mobElDescription}>{profileInfo.glazingWidth}</span>
                            </div>
                            <div className={styles.mobTechRow}>
                                <span className={styles.mobElName}>Удаленность фурнитурного паза</span>
                                <span className={styles.mobElDescription}>{profileInfo.distanceOfTheHardwareGroove}</span>
                            </div>
                            <div className={styles.mobTechRow}>
                                <span className={styles.mobElName}>Высота рамы</span>
                                <span className={styles.mobElDescription}>{profileInfo.frameHeight}</span>
                            </div>
                            <div className={styles.mobTechRow}>
                                <span className={styles.mobElName}>Высота створки</span>
                                <span className={styles.mobElDescription}>{profileInfo.sashHeight}</span>
                            </div>
                        </div>
                    </div>
                </div>
        }</>

    );
};

export default ProfilesInfo;
