import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import './otkosy.css';
import advantage1 from './images/advantage1.svg';
import advantage2 from './images/advantage2.svg';
import advantage3 from './images/advantage3.svg';
import advantage1mob from './images/advantage1mob.svg';
import advantage2mob from './images/advantage2mob.svg';
import advantage3mob from './images/advantage3mob.svg';

import mainImg from './images/main-image.svg';
import icon_bld from './images/icon-bld.svg';
import icon_bin from './images/icon-bin.svg';
import icon_clocks from './images/icon-clocks.svg';
import icon_doc from './images/icon-doc.svg';
import icon_helmet from './images/icon-helmet.svg';
import icon_window from './images/icon-window.svg';
import icon from './images/icon.svg';

import drill from './images/drill.svg';
import hammer from './images/hammer.svg';

import mob_termometr from './images/mob-termometr.svg';
import UpperMenu from '../../Elements/UpperMenu.jsx';
import Component13 from '../../Elements/mainPage/Component13.jsx';
import ContactForm from '../../contact-form/ContactForm.jsx';

const Otkosy = () => {
  const menuArr = ['О компании', 'История', 'Лицензии', 'Партнерам', 'Поставщикам', 'Документы'];

  const [activePage, setActivePage] = useState('');

  const [isContactFormVisible, setContactFormVisible] = useState(false);

  const handleToggleContactForm = () => {
    setContactFormVisible(!isContactFormVisible);
  };

  const handleModalClose = () => {
    setContactFormVisible(false);
  };

  const [isDesktop, setIsDesktop] = useState(window.innerWidth);
  const handleResize = () => {
    setIsDesktop(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Пластиковые Окна Окна ПВХ</title>
        <meta
          name="description"
          content="Пластиковые окна

          Пластиковые окна Пластиковые окна — это современное и практичное решение для вашего дома или квартиры. Они обладают множеством преимуществ, которые делают их лучшим выбором для остекления любых помещений. Вот некоторые из них:
          
          - Изделия из ПВХ имеют высокую теплоизоляцию и энергоэффективность. Они не пропускают холод и сквозняки, сохраняют тепло внутри и снижают расходы на отопление. Такие окна также защищают от перегрева в жаркое время года, благодаря специальным стеклопакетам с солнцезащитой.
          - Эти конструкции обеспечивают хорошую защиту от шума. Они изолируют внешние звуки и создают тихую и уютную атмосферу в доме. Они также устойчивы к взлому и повреждениям, благодаря качественной фурнитуре и противовзломным системам.
          - Оконные рамы из пластика легко устанавливаются и обслуживаются. Они не нуждаются в покраске или ремонте, не коррозируют и не деформируются. Они легко моются и не теряют свой цвет и блеск со временем. Они также имеют долгий срок службы — до 50 лет и более.
          - Оконные блоки из пластика имеют разнообразный дизайн и подходят для любого интерьера. Вы можете выбрать их любого вида, размера и формы: арочные, эркерные, панорамные, раздвижные, подъемно-раздвижные и другие. Вы также можете выбрать их любого цвета: белые, черные, цветные, ламинированные под дерево или камень.
          - Они подходят для остекления любых помещений: квартир, домов, балконов, лоджий, террас, веранд, детских, коттеджей и других. Они создают комфорт и улучшают микроклимат в помещениях. Они обеспечивают свежий воздух и оптимальную влажность, благодаря системам вентиляции и конденсата. Они также предотвращают появление плесени и грибка, которые могут нанести вред здоровью.
          - Они экологичны и безопасны для окружающей среды. Они не содержат вредных веществ и не выделяют токсичных газов. Они также экономят электроэнергию и снижают выбросы углекислого газа. Они изготавливаются из перерабатываемых материалов и могут быть утилизированы после окончания срока службы.
          Как вы видите, пластиковые окна — это идеальный вариант для вашего дома или квартиры. Они сочетают в себе качество, функциональность, эстетику и экономию. "
        />
        <meta property="og:title" content="Пластиковые Окна Окна ПВХ" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://ok-schtern.ru/" />
        <meta property="og:image" content="URL_ИЗОБРАЖЕНИЯ" />
        <meta
          property="og:description"
          content="Пластиковые окна — это современное и практичное решение для вашего дома или квартиры."
        />
        <meta property="og:site_name" content="SCHTERN" />
        <meta property="og:locale" content="ru_RU" />
      </Helmet>
      <UpperMenu />

      {isDesktop > 480 ? (
        <>
          <div className="otkosy">
            <h2>Отделка откосов</h2>
            <div className="otkosy__1">
              <img src={mainImg} alt="main-image_otkosy" className="otkosy__main-image" />
              <div className="otkosy__1--text">
                На сегодняшний день откосы делают при помощи штукатурного раствора, из
                сэндвич-панелей или листового пластика.
              </div>
            </div>
            <div className="otkosy__2">
              <img src={icon} alt="" />
              <div className="otkosy__2--text">
                Оформите заявку на услугу, мы свяжемся с вами в ближайшее время и ответим на все
                интересующие вопросы.
              </div>
              <div className="otkosy__2--link" onClick={handleToggleContactForm}>
                Отправить заявку
              </div>
            </div>

            {/* <div className="otkosy__nav">
              {menuArr.map((item) => (
                <p
                  onClick={(event) => setActivePage(event.target.textContent)}
                  className={
                    activePage === item ? 'otkosy__nav--activeTitle' : 'otkosy__nav--title'
                  }>
                  {item}
                </p>
              ))}
            </div> */}
          </div>

          <div className="otkosy__info-container">
            <div className="otkosy__info-container--grids">
              <div className="otkosy__info-container--grid3">
                Пластиковые откосы - это лучший вариант для окон из ПВХ. Специалисты нашей компании
                используют для отделки откосов сэндвич-панель, которая представляет собой вспененный
                утеплитель, помещенный между двух пластиковых панелей - пластиковые откосы.  <br />
                <br />
                Внешним слоем и одновременно декоративной поверхностью сэндвич-панели является
                матовый или глянцевый лист ПВХ толщиной 1 мм. Общая толщина панели для пластиковых
                откосов 10 мм. Данные материалы могут использоваться при отделке откосов любой
                конфигурации, как в панельных и кирпичных домах, так и в коттеджах. 
              </div>
            </div>
            <div className="otkosy__adventages">
              <h2 className="otkosy__advantages--h2">Преимущества наших окон</h2>
              <div className="otkosy__adventages--container">
                <div className="otkosy__adventage--1">
                  <img src={advantage1} alt="otkosy__adventage" />
                </div>
                <div className="otkosy__adventage--2">
                  <img src={advantage2} alt="otkosy__adventage" />
                </div>
                <div className="otkosy__adventage--3">
                  <img src={advantage3} alt="otkosy__adventage" />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="mob-otkosy">
            <h2>Отделка откосов</h2>
            <div className="mob-otkosy__1">
              <img src={mainImg} alt="main-image_mob-otkosy" className="mob-otkosy__main-image" />
              <div className="mob-otkosy__1--text">
                На сегодняшний день откосы делают при помощи штукатурного раствора, из
                сэндвич-панелей или листового пластика.
              </div>
            </div>
            <div className="mob-otkosy__2">
              <img src={icon} alt="" />
              <div className="mob-otkosy__2--container">
                <div className="mob-otkosy__2--text">
                  Оформите заявку на услугу, мы свяжемся с вами в ближайшее время и ответим на все
                  интересующие вопросы.
                </div>
                <div className="mob-otkosy__2--link" onClick={handleToggleContactForm}>
                  Отправить заявку
                </div>
              </div>
            </div>

            {/* <div className="mob-otkosy__nav">
              {menuArr.map((item) => (
                <p
                  onClick={(event) => setActivePage(event.target.textContent)}
                  className={
                    activePage === item ? 'mob-otkosy__nav--activeTitle' : 'mob-otkosy__nav--title'
                  }>
                  {item}
                </p>
              ))}
            </div> */}
          </div>

          <div className="mob-otkosy__info-container">
            <div className="mob-otkosy__info-container--grids">
              <div className="mob-otkosy__info-container--grid3">
                Пластиковые откосы - это лучший вариант для окон из ПВХ. Специалисты нашей компании
                используют для отделки откосов сэндвич-панель, которая представляет собой вспененный
                утеплитель, помещенный между двух пластиковых панелей - пластиковые откосы.  <br />
                <br />
                Внешним слоем и одновременно декоративной поверхностью сэндвич-панели является
                матовый или глянцевый лист ПВХ толщиной 1 мм. Общая толщина панели для пластиковых
                откосов 10 мм. Данные материалы могут использоваться при отделке откосов любой
                конфигурации, как в панельных и кирпичных домах, так и в коттеджах. 
              </div>
            </div>
            <div className="mob-otkosy__adventages">
              <h2 className="mob-otkosy__advantages--h2">Преимущества наших окон</h2>
              <div className="mob-otkosy__adventages--container">
                <div className="mob-otkosy__adventage--1">
                  <img src={advantage1mob} alt="mob-otkosy__adventage" />
                </div>
                <div className="mob-otkosy__adventage--2">
                  <img src={advantage2mob} alt="mob-otkosy__adventage" />
                </div>
                <div className="mob-otkosy__adventage--3">
                  <img src={advantage3mob} alt="mob-otkosy__adventage" />
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <Component13 />
      {isContactFormVisible && (
        <div className="modal-overlay">
          <ContactForm onClose={handleModalClose} />
        </div>
      )}
    </>
  );
};
export default Otkosy;
