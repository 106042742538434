import React from 'react';

const Phone = ({
    color = 'white',
               }) => {
    return (
        <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.272 2.18311C10.7456 0.91168 9.35799 0.234951 8.03187 0.59724L2.01651 2.23779C0.827112 2.5659 0 3.64593 0 4.87635C0 21.7877 13.7123 35.5 30.6236 35.5C31.8541 35.5 32.9341 34.6729 33.2622 33.4835L34.9028 27.4681C35.265 26.142 34.5883 24.7544 33.3169 24.228L26.7547 21.4938C25.6405 21.029 24.3485 21.3502 23.5898 22.2867L20.8282 25.6567C16.0159 23.3804 12.1196 19.4841 9.84332 14.6718L13.2133 11.9171C14.1498 11.1515 14.471 9.86636 14.0062 8.75215L11.272 2.18994V2.18311Z"
                fill={color}/>
        </svg>

    );
};

export default Phone;
