import React from 'react';
import '../../../css/mainPage/component6.css'; // Импорт соответствующего файла стилей
import man from '../../../assest/svgEl/main/man-c6.svg';
import { Link } from 'react-router-dom';

const Component6 = () => {
  return (
    <div className="component6">
      <div className="our-services-c6">Наши услуги</div>
      <div className="man-c6">
        <img src={man} alt="man" className="man-c6-img" />
      </div>
      <div className="services-с6"></div>
      <div className="grid-container">
        <div className="grid-item red"></div>
        <div className="grid-item light-red">
          <div className="whitebox-c6">
            <p className="service-c6">Установка</p>
            <p className="service-c6">пластиковых окон </p>
            <p className="service-c6">по ГОСТу</p>
            <Link to={'/ustanovka'}>
              <p className="more1-c6">Подробнее</p>
            </Link>
          </div>
          <div className="shadow1-c6"></div>
        </div>
        <div className="grid-item light-orange">
          <div className="whitebox-c6">
            <p className="service-c6">Отделка откосов</p>
            <Link to={'/otkosy'}>
              <p className="more2-c6">Подробнее</p>
            </Link>
          </div>
          <div className="shadow2-c6"></div>
        </div>
        <div className="grid-item dark-orange">
          <div className="whitebox-c6">
            <p className="service-c6">Доставка окон</p>
            <p className="service-c6">По Липецку и области</p>
            <Link to={'/dostavka'}>
              <p className="more3-c6">Подробнее</p>
            </Link>
          </div>
          <div className="shadow3-c6"></div>
        </div>
      </div>
    </div>
  );
};

export default Component6;
