import React from 'react';
import '../../../css/mainPage/component11.css'; // Импорт соответствующего файла стилей
import arrow from '../../../assest/svgEl/arrow.svg';

const ComponentEx = () => {
  return (
    <div className="component11">
      <h1>Вопрос-Ответ</h1>

      <div className="question-container-c11">
        <div className="question-box-c11">
          <p>
            Можно ли ставить<br></br> пластиковые окна зимой?
          </p>
          <img src={arrow} alt="arrow" />
        </div>
        <div className="question-box-c11">
          <p>
            Почему точную цену <br></br> нельзя узнать по телефону?
          </p>
          <img src={arrow} alt="arrow" />
        </div>
        <div className="question-box-c11">
          <p>Сколько стоит замер окон?</p>
          <img src={arrow} alt="arrow" />
        </div>
      </div>
    </div>
  );
};

export default ComponentEx;
