import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './Istoriya.css';
import zavod from './images/zavod.svg';
import earth from './images/earth.svg';
import okno from './images/okno1.svg';
import okno2 from './images/window2.svg';
import logo from './images/logo.svg';
import bg from './images/history-top.svg';
import mob_bg from './images/mob_bg.svg';
import UpperMenu from '../../../components/Elements/UpperMenu.jsx';
import Component13 from '../../../components/Elements/mainPage/Component13.jsx';
import Sertifikaty from './Sertifikaty.jsx';

const Istoriya = () => {
  const menuArr = ['О компании', 'История', 'Лицензии', 'Партнерам', 'Поставщикам', 'Документы'];

  const [activePage, setActivePage] = useState('');

  const [isDesktop, setIsDesktop] = useState(window.innerWidth);
  const handleResize = () => {
    setIsDesktop(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Пластиковые Окна Окна ПВХ</title>
        <meta
          name="description"
          content="Пластиковые окна

          Пластиковые окна Пластиковые окна — это современное и практичное решение для вашего дома или квартиры. Они обладают множеством преимуществ, которые делают их лучшим выбором для остекления любых помещений. Вот некоторые из них:
          
          - Изделия из ПВХ имеют высокую теплоизоляцию и энергоэффективность. Они не пропускают холод и сквозняки, сохраняют тепло внутри и снижают расходы на отопление. Такие окна также защищают от перегрева в жаркое время года, благодаря специальным стеклопакетам с солнцезащитой.
          - Эти конструкции обеспечивают хорошую защиту от шума. Они изолируют внешние звуки и создают тихую и уютную атмосферу в доме. Они также устойчивы к взлому и повреждениям, благодаря качественной фурнитуре и противовзломным системам.
          - Оконные рамы из пластика легко устанавливаются и обслуживаются. Они не нуждаются в покраске или ремонте, не коррозируют и не деформируются. Они легко моются и не теряют свой цвет и блеск со временем. Они также имеют долгий срок службы — до 50 лет и более.
          - Оконные блоки из пластика имеют разнообразный дизайн и подходят для любого интерьера. Вы можете выбрать их любого вида, размера и формы: арочные, эркерные, панорамные, раздвижные, подъемно-раздвижные и другие. Вы также можете выбрать их любого цвета: белые, черные, цветные, ламинированные под дерево или камень.
          - Они подходят для остекления любых помещений: квартир, домов, балконов, лоджий, террас, веранд, детских, коттеджей и других. Они создают комфорт и улучшают микроклимат в помещениях. Они обеспечивают свежий воздух и оптимальную влажность, благодаря системам вентиляции и конденсата. Они также предотвращают появление плесени и грибка, которые могут нанести вред здоровью.
          - Они экологичны и безопасны для окружающей среды. Они не содержат вредных веществ и не выделяют токсичных газов. Они также экономят электроэнергию и снижают выбросы углекислого газа. Они изготавливаются из перерабатываемых материалов и могут быть утилизированы после окончания срока службы.
          Как вы видите, пластиковые окна — это идеальный вариант для вашего дома или квартиры. Они сочетают в себе качество, функциональность, эстетику и экономию. "
        />
        <meta property="og:title" content="Пластиковые Окна Окна ПВХ" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://ok-schtern.ru/" />
        <meta property="og:image" content="URL_ИЗОБРАЖЕНИЯ" />
        <meta
          property="og:description"
          content="Пластиковые окна — это современное и практичное решение для вашего дома или квартиры."
        />
        <meta property="og:site_name" content="SCHTERN" />
        <meta property="og:locale" content="ru_RU" />
      </Helmet>
      <UpperMenu />

      {isDesktop > 480 ? (
        <>
          <div className="history__top">
            <div className="history__top--img">
              <img src={bg} alt="" />
            </div>
          </div>
          <div className="history">
            <h2>История</h2>
            <div className="history__mainBox">
              <div className="history__mainBox--2000">
                <div className="history__mainBox--bigText">2000</div>
                <div className="history__mainBox--img">
                  <img src={zavod} alt="завод" />
                </div>

                <div className="history__mainBox--text">
                  Более 20 лет назад на горизонте молодого российского оконного бизнеса, была
                  создана наша компания. Согласитесь, что в экономике России присутствует очень
                  небольшое количество производителей, которые могут похвастаться такой долгой и
                  успешной историей.
                </div>
              </div>
              <div className="history__mainBox--2001">
                <div className="history__mainBox--bigText">2008</div>
                <div className="history__mainBox--img">
                  <img src={okno} alt="земля" />
                </div>
                <div className="history__mainBox--text">
                  Несмотря на все сложности: дефицит квалифицированных кадров, высокую конкуренцию,
                  глобальный экономический кризис 2008 года, в течение этих лет компания наращивала
                  объемы производства, расширяла географию продаж, добивалась высоких результатов в
                  экономической деятельности, не останавливаясь на пути своего развития.
                </div>
              </div>
              <div className="history__mainBox--2002">
                <div className="history__mainBox--bigText">2009</div>
                <div className="history__mainBox--img">
                  <img src={okno2} alt="окно" />
                </div>
                <div className="history__mainBox--text">
                  Первая профильная система, с которой работала наша компания, называлась
                  «Knipping». Ассортимент профильной продукции постоянно расширялся - Weltplast,
                  Deceuninck, SCHÜCO.
                </div>
              </div>
              <div className="history__mainBox--2003">
                <div className="history__mainBox--bigText">2010</div>
                <div className="history__mainBox--img">
                  <img src={earth} alt="окно" />
                </div>
                <div className="history__mainBox--text">
                  В 2010 году компания приняла решение приступить к собственной экструзии профиля.
                  Нами разработана система нового профиля SCHTERN. В мощном названии новой
                  профильной системы воплотилось стремление создателей к непревзойденному немецкому
                  качеству при сохранении условия доступности для каждого покупателя.
                </div>
              </div>
              <div className="history__mainBox--2004">
                <div className="history__mainBox--bigText">2012</div>
                <div className="history__mainBox--img">
                  <img src={logo} alt="лого" />
                </div>
                <div className="history__mainBox--text">
                  В 2012 году было принято решение об обновлении фирменного стиля, модернизации
                  логотипа, корпоративного вэб-сайта и брендбука. Одной из основных задач, стоящих
                  перед руководством компании и рабочей группой по проекту, стала разработка
                  неповторимого фирменного стиля.
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {' '}
          <>
            <div className="mob_history__top">
              <div className="mob_history__top--img">
                <img src={mob_bg} alt="" />
              </div>
            </div>
            <div className="mob_history">
              <h2>История</h2>
              <div className="mob_history__mainBox">
                <div className="mob_history__mainBox--2000">
                  <div className="mob_history__mainBox--bigText">2000</div>
                  <div className="mob_history__mainBox--text-img-block">
                    <div className="mob_history__mainBox--img">
                      <img src={zavod} alt="завод" />
                    </div>

                    <div className="mob_history__mainBox--text">
                      Более 20 лет назад на горизонте молодого российского оконного бизнеса, была
                      создана наша компания. Согласитесь, что в экономике России присутствует очень
                      небольшое количество производителей, которые могут похвастаться такой долгой и
                      успешной историей.
                    </div>
                  </div>
                </div>
                <div className="mob_history__mainBox--2001">
                  <div className="mob_history__mainBox--bigText">2008</div>
                  <div className="mob_history__mainBox--text-img-block">
                    <div className="mob_history__mainBox--img">
                      <img src={okno} alt="земля" />
                    </div>
                    <div className="mob_history__mainBox--text">
                      Несмотря на все сложности: дефицит квалифицированных кадров, высокую
                      конкуренцию, глобальный экономический кризис 2008 года, в течение этих лет
                      компания наращивала объемы производства, расширяла географию продаж,
                      добивалась высоких результатов в экономической деятельности, не останавливаясь
                      на пути своего развития.
                    </div>
                  </div>
                </div>
              </div>
              <div className="mob_history__mainBox--2002">
                <div className="mob_history__mainBox--bigText">2009</div>
                <div className="mob_history__mainBox--text-img-block">
                  <div className="mob_history__mainBox--text-img-block">
                    <div className="mob_history__mainBox--img">
                      <img src={okno2} alt="окно" />
                    </div>
                    <div className="mob_history__mainBox--text">
                      Первая профильная система, с которой работала наша компания, называлась
                      «Knipping». Ассортимент профильной продукции постоянно расширялся - Weltplast,
                      Deceuninck, SCHÜCO.
                    </div>
                  </div>
                </div>
                <div className="mob_history__mainBox--2003">
                  <div className="mob_history__mainBox--bigText">2010</div>
                  <div className="mob_history__mainBox--text-img-block">
                    <div className="mob_history__mainBox--img">
                      <img src={earth} alt="окно" />
                    </div>
                    <div className="mob_history__mainBox--text">
                      В 2010 году компания приняла решение приступить к собственной экструзии
                      профиля. Нами разработана система нового профиля SCHTERN. В мощном названии
                      новой профильной системы воплотилось стремление создателей к непревзойденному
                      немецкому качеству при сохранении условия доступности для каждого покупателя.
                    </div>
                  </div>
                </div>
                <div className="mob_history__mainBox--2004">
                  <div className="mob_history__mainBox--bigText">2012</div>
                  <div className="mob_history__mainBox--text-img-block">
                    <div className="mob_history__mainBox--img">
                      <img src={logo} alt="лого" />
                    </div>
                    <div className="mob_history__mainBox--text">
                      В 2012 году было принято решение об обновлении фирменного стиля, модернизации
                      логотипа, корпоративного вэб-сайта и брендбука. Одной из основных задач,
                      стоящих перед руководством компании и рабочей группой по проекту, стала
                      разработка неповторимого фирменного стиля.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </>
      )}
      <Component13 />
    </>
  );
};
export default Istoriya;
