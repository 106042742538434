import React, { useState, useEffect } from 'react';
import '../../css/burgerMenuMob.css';
import window1 from '../../assest/svgEl/oknoMenu1.svg';
import window2 from '../../assest/svgEl/oknoMenu2.svg';
import arrow from '../../assest/svgEl/arrow.svg';
import arrowBack from '../../assest/svgEl/arrowBack.svg';
import arrowBlue from '../../assest/svgEl/arrowBlue.svg';
import logo from '../../assest/svgEl/logo.svg';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

const BurgerMenuMob = ({ isOpen, toggleBM, menu }) => {
  const classNames = isOpen ? 'burger-menu open' : 'burger-menu';

  const [rightMenu, setRightMenu] = useState([]);
  const [secondFloor, setSecondFloor] = useState([]);
  const [thirdFloor, setThirdFloor] = useState([]);
  const [name, setName] = useState('');

  const [rightSectionStyle, setRightSectionStyle] = useState('sectionRs section-right');

  const updateArrows = (obj) => {
    setRightMenu([]);
    setSecondFloor(obj.array);
    setName(obj.name);

    setRightSectionStyle('sectionRs section-right');
  };

  const resetFloors = () => {
    setSecondFloor([]);
    setThirdFloor([]);
  };

  const thirdFloorCreate = (obj) => {
    setThirdFloor(obj.array);
    setName(obj.name);
  };

  const resetState = () => {
    toggleBM();

    setName('');
    setSecondFloor([]);
    setThirdFloor([]);

    setRightMenu([]);
    setRightSectionStyle('sectionRs section-right');
  };

  return (
    <div className={classNames}>
      {secondFloor.length === 0 ? (
        <div className="sectionRs section-left">
          <div>
            <img src={logo}></img>
          </div>

          <div>
            {menu.map((obj, index) => (
              <div key={index} onClick={() => updateArrows(obj)} className="testMob">
                {obj.array.length > 0 ? (
                  <>
                    <span className="menu-text">
                      <span className="text">{obj.name}</span>
                    </span>
                    <img src={arrow} alt="Arrow" style={{ marginLeft: '10px' }} />
                  </>
                ) : (
                  <span className="menu-text">
                    {obj.link ? (
                      <Link to={obj.link}>
                        <span className="text">{obj.name}</span>
                      </Link>
                    ) : (
                      <span className="text">{obj.name}</span>
                    )}
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : thirdFloor.length !== 0 ? (
        <div className="sectionRs section-left">
          <div>
            <span className="menu-text">
              <span style={{ color: '#87DFEE' }}>{name}</span>
            </span>
            <img
              src={arrowBlue}
              alt="Arrow"
              style={{ marginLeft: '10px', transform: 'rotate(90deg)' }}
            />
          </div>

          <div>
            {thirdFloor.map((obj, index) => (
              <div key={index} onClick={() => updateArrows(obj.array)} className="testMob">
                {obj.array.length > 0 ? (
                  <>
                    <span className="menu-text">
                      <span className="text">{obj.name}</span>
                    </span>
                    <img src={arrow} alt="Arrow" style={{ marginLeft: '10px' }} />
                  </>
                ) : (
                  <span className="menu-text">
                    {obj.link ? (
                      <Link to={obj.link}>
                        <span className="text">{obj.name}</span>
                      </Link>
                    ) : (
                      <span className="text">{obj.name}</span>
                    )}
                  </span>
                )}
              </div>
            ))}
          </div>
          <button className="toggle-button-left" onClick={() => resetFloors()}>
            <img
              src={arrowBack}
              alt="Arrow"
              style={{ marginLeft: '10px', transform: 'rotate(180deg)' }}
            />
          </button>
        </div>
      ) : (
        <div className="sectionRs section-left">
          <div>
            <span className="menu-text">
              <span style={{ color: '#87DFEE' }}>{name}</span>
            </span>
            <img
              src={arrowBlue}
              alt="Arrow"
              style={{ marginLeft: '10px', transform: 'rotate(90deg)' }}
            />
          </div>

          <div>
            {secondFloor.map((obj, index) => (
              <div key={index} onClick={() => thirdFloorCreate(obj)} className="testMob">
                {obj.array.length > 0 ? (
                  <>
                    <span className="menu-text">
                      <span className="text">{obj.name}</span>
                    </span>
                    <img src={arrow} alt="Arrow" style={{ marginLeft: '10px' }} />
                  </>
                ) : (
                  <span className="menu-text">
                    <span className="text">{obj.name}</span>
                  </span>
                )}
              </div>
            ))}
          </div>
          <button className="toggle-button-left" onClick={() => setSecondFloor([])}>
            <img
              src={arrowBack}
              alt="Arrow"
              style={{ marginLeft: '10px', transform: 'rotate(180deg)' }}
            />
          </button>
        </div>
      )}{' '}
      <button className="toggle-button" onClick={() => resetState()}>
        &times;
      </button>
      <div className="down-windows">
        <div className="left-window">
          <img alt="winwdow right" style={{ height: '130px' }} src={window2} />
        </div>
        <div className="right-window">
          <img alt="winwdow right" style={{ height: '130px' }} src={window1} />
        </div>
      </div>
    </div>
  );
};

export default BurgerMenuMob;
