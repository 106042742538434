import React from 'react';
import Component13 from './Elements/mainPage/Component13';
import UpperMenu from './Elements/UpperMenu';

import img from '../../src/assest/svgEl/notWorking.svg';
const TMPage = () => {
  return (
    <>
      <UpperMenu />
      <div>
        <img
          src={img}
          alt="Page doesn't working"
          style={{ width: '100vw', marginTop: 'calc(80/1440 * 100vw)' }}
        />
      </div>
      <Component13 />
    </>
  );
};
export default TMPage;
