import React, { useState, useEffect } from 'react';
import './BurgerMenuNew.css';
import window1 from '../../../assest/svgEl/oknoMenu1.svg';
import window2 from '../../../assest/svgEl/oknoMenu2.svg';
import arrow from '../../../assest/svgEl/arrow.svg';
import arrowBlue from '../../../assest/svgEl/arrowBlue.svg';
import window1_mob from './imgs/window1.svg';
import window2_mob from './imgs/window2.svg';
import window3_mob from './imgs/window3.svg';
import arrow_mob from './imgs/arrow.svg';

import logo from '../../../assest/svgEl/logo.svg';
import { BrowserRouter as Router, Route, Link, NavLink } from 'react-router-dom';
import menu from './menu.js';

const BurgerMenuNew = ({ isOpen, toggleBM }) => {
  const menuOpacity = !isOpen ? 'transparent' : 'no-transparent';

  const [isDesktop, setIsDesktop] = useState(window.innerWidth);

  const handleResize = () => {
    setIsDesktop(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Добавляем стиль overflow: hidden; к body, когда меню открыто
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = ''; // Удаляем стиль, когда меню закрыто
    }

    return () => {
      // Очищаем стиль при размонтировании компонента
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  const [leftSelected, setLeftSelerted] = useState('');
  const [middleSelected, setMiddleSelerted] = useState('');

  const [middleArr, setMiddleArr] = useState([]);
  const [rightArr, setRightArr] = useState([]);

  const middleSelectionFunc = (selectedTitle, selectedArr) => {
    setLeftSelerted(selectedTitle);
    setMiddleArr(selectedArr);
    setRightArr([]);
    setMiddleSelerted('');
  };

  const rightSelectionFunc = (selectedTitle, selectedArr) => {
    setMiddleSelerted(selectedTitle);
    setRightArr(selectedArr);
  };

  const resetState = () => {
    setLeftSelerted('');
    setMiddleSelerted('');
    setMiddleArr([]);
    setRightArr([]);
    toggleBM();
  };

  const rightBackBtn = () => {
    setRightArr([]);
    setMiddleSelerted('');
  };

  const middleBackBtn = () => {
    setMiddleArr([]);
    setLeftSelerted('');
  };

  return (
    <>
      {isDesktop > 480 ? (
        <div className={menuOpacity}>
          <div className="burger-menu1">
            <div
              className={
                middleArr.length === 0 ? 'burger-menu1__left-border' : 'burger-menu1__left'
              }
              style={middleArr.length !== 0 ? { borderRight: '0.2vw solid #161E54' } : {}}>
              <div className="burger-menu1__left--sheet">
                {menu.map((item) =>
                  item.link ? (
                    <NavLink
                      to={item.link}
                      onClick={() => resetState()}
                      className="burger-menu1__custom-link">
                      <div className="burger-menu1__item-link">{item.name}</div>
                    </NavLink>
                  ) : (
                    <div
                      className={
                        leftSelected === item.name
                          ? 'burger-menu1__item-blue-arrow'
                          : 'burger-menu1__item-arrow'
                      }
                      onClick={() => middleSelectionFunc(item.name, item.array)}>
                      {item.name}&nbsp;&nbsp;
                      <img
                        src={leftSelected === item.name ? arrowBlue : arrow}
                        alt=""
                        style={{ width: '0.9vw' }}
                      />
                    </div>
                  )
                )}
              </div>
            </div>
            <div
              className={
                middleArr.length === 0 ? 'burger-menu1__middle-border' : 'burger-menu1__middle'
              }
              style={rightArr.length !== 0 ? { borderRight: '0.2vw solid #161E54' } : {}}>
              {middleArr.length === 0 ? (
                <img src={window1} alt="window middle" className="burger-menu1__window1"></img>
              ) : (
                <div className="burger-menu1__middle--sheet">
                  {middleArr?.map((item) =>
                    item.link ? (
                      <NavLink
                        to={item.link}
                        onClick={() => resetState()}
                        className="burger-menu1__custom-link">
                        <div className="burger-menu1__item-link">{item.name}</div>
                      </NavLink>
                    ) : (
                      <div
                        className={
                          middleSelected === item.name
                            ? 'burger-menu1__item-blue-arrow'
                            : 'burger-menu1__item-arrow'
                        }
                        onClick={() => rightSelectionFunc(item.name, item.array)}>
                        {item.name} &nbsp;&nbsp;
                        <img
                          src={middleSelected === item.name ? arrowBlue : arrow}
                          alt=""
                          style={{ width: '0.9vw' }}
                        />
                      </div>
                    )
                  )}
                </div>
              )}
            </div>
            <div
              className={
                rightArr.length === 0 ? 'burger-menu1__right-border' : 'burger-menu1__right'
              }>
              {rightArr.length === 0 ? (
                <img src={window2} alt="window right" className="burger-menu1__window2"></img>
              ) : (
                <div className="burger-menu1__right--sheet">
                  {rightArr?.map((item) => (
                    <NavLink
                      to={item.link}
                      onClick={() => resetState()}
                      className="burger-menu1__custom-link">
                      <div className="burger-menu1__item-link">{item.name}</div>
                    </NavLink>
                  ))}
                </div>
              )}
              <div className="burger-menu1__close-btn" onClick={() => resetState()}>
                &times;
              </div>
            </div>
          </div>
        </div>
      ) : (
        // Мобильная версия
        <div className={menuOpacity}>
          <div className="mob-burger-menu1">
            {middleArr.length === 0 && rightArr.length === 0 ? (
              <div className="mob-burger-menu1__left">
                {menu.map((item) =>
                  item.link ? (
                    <NavLink
                      to={item.link}
                      onClick={() => resetState()}
                      className="mob_burger-menu1__custom-link">
                      <div className="mob_burger-menu1__item-link">{item.name}</div>
                    </NavLink>
                  ) : (
                    <div
                      className={
                        leftSelected === item.name
                          ? 'mob-burger-menu1__item-blue-arrow'
                          : 'mob-burger-menu1__item-arrow'
                      }
                      onClick={() => middleSelectionFunc(item.name, item.array)}>
                      {item.name}&nbsp;&nbsp;
                      <img src={leftSelected === item.name ? arrowBlue : arrow} alt="" />
                    </div>
                  )
                )}
              </div>
            ) : middleArr.length !== 0 && rightArr.length === 0 ? (
              <div className="mob-burger-menu1__middle">
                <div className="mob-menu-burger1__title">{leftSelected}</div>

                {middleArr?.map((item) =>
                  item.link ? (
                    <Link
                      to={item.link}
                      onClick={() => resetState()}
                      className="mob_burger-menu1__custom-link">
                      <div className="burger-menu1__item-link">{item.name}</div>
                    </Link>
                  ) : (
                    <div
                      className={
                        middleSelected === item.name
                          ? 'mob-burger-menu1__item-blue-arrow'
                          : 'mob-burger-menu1__item-arrow'
                      }
                      onClick={() => rightSelectionFunc(item.name, item.array)}>
                      {item.name} &nbsp;
                      <img src={middleSelected === item.name ? arrowBlue : arrow} alt="" />
                    </div>
                  )
                )}
              </div>
            ) : (
              <div className="mob-burger-menu1__right">
                <div className="mob-menu-burger1__title">{middleSelected}</div>

                {rightArr?.map((item) => (
                  <Link
                    to={item.link}
                    onClick={() => resetState()}
                    className="mob_burger-menu1__custom-link">
                    <div className="burger-menu1__item-link">{item.name}</div>
                  </Link>
                ))}
              </div>
            )}

            <div
              className="mob-burger-menu1_buttons-container__btn-back"
              style={
                middleArr.length === 0 && rightArr.length === 0
                  ? { display: 'none' }
                  : { display: '' }
              }
              onClick={rightArr.length !== 0 ? () => rightBackBtn() : () => middleBackBtn()}>
              <img src={arrow_mob} alt="arrow back" /> &nbsp; назад
            </div>

            <div
              className="mob-burger-menu1__footer"
              style={
                middleArr.length && rightArr.length === 0
                  ? { backgroundColor: '#F18B6D' }
                  : rightArr.length === 0
                  ? { backgroundColor: '#FDD8A6' }
                  : { backgroundColor: '#F9C5B9' }
              }>
              <img
                src={
                  middleArr.length && rightArr.length === 0
                    ? window1_mob
                    : rightArr.length === 0
                    ? window2_mob
                    : window3_mob
                }
                alt="window-footer"
                style={{ height: '22vh' }}
              />
            </div>
            <div
              className="mob-burger-menu1_buttons-container__btn-cls"
              onClick={() => resetState()}>
              &times;
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BurgerMenuNew;
