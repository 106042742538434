import React, {useEffect, useState} from 'react';
import styles from './profileButton.module.css'

const ProfileButton = ({
                           name,
                           active
}) => {

    const [isDesktop, setIsDesktop] = useState(window.innerWidth);
    const handleResize = () => {
        setIsDesktop(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            {isDesktop > 480 ?
                <div className={(active ? ' ' + styles.buttonActive : styles.buttonNonActive)}>
                    <span>Профиль {name}</span>
                </div>
                :
                <div className={(active ? ' ' + styles.mobButtonActive : styles.mobButtonNonActive)}>
                    <span>Профиль {name}</span>
                </div>
            }
        </>

    );
};

export default ProfileButton;
