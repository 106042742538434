import React from 'react';

const KalkulyatorOkon = () => {

    return (
        <>
            <p>Калькулятор окон</p>
        </>
    )
}

export default KalkulyatorOkon;