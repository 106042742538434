import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Textfit from 'react-textfit';
import './partneram.css';
import mainImg from './images/main-image.svg';
import truck from './images/truck.svg';
import woman from './images/woman.svg';
import clocks from './images/clocks.svg';
import frame from './images/frame.svg';
import bike from './images/bike.svg';
import books from './images/books.svg';
import laptop from './images/laptop.svg';
import bg_mob from './images/mob/bg.svg';
import UpperMenu from '../../../components/Elements/UpperMenu.jsx';
import Component13 from '../../../components/Elements/mainPage/Component13.jsx';

const Partneram = () => {
  const menuArr = ['О компании', 'История', 'Лицензии', 'Партнерам', 'Поставщикам', 'Документы'];

  const [activePage, setActivePage] = useState('');

  const [isDesktop, setIsDesktop] = useState(window.innerWidth);
  const handleResize = () => {
    setIsDesktop(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Пластиковые Окна Окна ПВХ</title>
        <meta
          name="description"
          content="Пластиковые окна

          Пластиковые окна Пластиковые окна — это современное и практичное решение для вашего дома или квартиры. Они обладают множеством преимуществ, которые делают их лучшим выбором для остекления любых помещений. Вот некоторые из них:
          
          - Изделия из ПВХ имеют высокую теплоизоляцию и энергоэффективность. Они не пропускают холод и сквозняки, сохраняют тепло внутри и снижают расходы на отопление. Такие окна также защищают от перегрева в жаркое время года, благодаря специальным стеклопакетам с солнцезащитой.
          - Эти конструкции обеспечивают хорошую защиту от шума. Они изолируют внешние звуки и создают тихую и уютную атмосферу в доме. Они также устойчивы к взлому и повреждениям, благодаря качественной фурнитуре и противовзломным системам.
          - Оконные рамы из пластика легко устанавливаются и обслуживаются. Они не нуждаются в покраске или ремонте, не коррозируют и не деформируются. Они легко моются и не теряют свой цвет и блеск со временем. Они также имеют долгий срок службы — до 50 лет и более.
          - Оконные блоки из пластика имеют разнообразный дизайн и подходят для любого интерьера. Вы можете выбрать их любого вида, размера и формы: арочные, эркерные, панорамные, раздвижные, подъемно-раздвижные и другие. Вы также можете выбрать их любого цвета: белые, черные, цветные, ламинированные под дерево или камень.
          - Они подходят для остекления любых помещений: квартир, домов, балконов, лоджий, террас, веранд, детских, коттеджей и других. Они создают комфорт и улучшают микроклимат в помещениях. Они обеспечивают свежий воздух и оптимальную влажность, благодаря системам вентиляции и конденсата. Они также предотвращают появление плесени и грибка, которые могут нанести вред здоровью.
          - Они экологичны и безопасны для окружающей среды. Они не содержат вредных веществ и не выделяют токсичных газов. Они также экономят электроэнергию и снижают выбросы углекислого газа. Они изготавливаются из перерабатываемых материалов и могут быть утилизированы после окончания срока службы.
          Как вы видите, пластиковые окна — это идеальный вариант для вашего дома или квартиры. Они сочетают в себе качество, функциональность, эстетику и экономию. "
        />
        <meta property="og:title" content="Пластиковые Окна Окна ПВХ" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://ok-schtern.ru/" />
        <meta property="og:image" content="URL_ИЗОБРАЖЕНИЯ" />
        <meta
          property="og:description"
          content="Пластиковые окна — это современное и практичное решение для вашего дома или квартиры."
        />
        <meta property="og:site_name" content="SCHTERN" />
        <meta property="og:locale" content="ru_RU" />
      </Helmet>
      <UpperMenu />

      {isDesktop > 480 ? (
        <>
          <div className="partneram">
            <img src={mainImg} alt="main-image_partneram" className="partneram__main-image" />
            {/* <div className="partneram__nav">
              {menuArr.map((item) => (
                <p
                  onClick={(event) => setActivePage(event.target.textContent)}
                  className={
                    activePage === item ? 'partneram__nav--activeTitle' : 'partneram__nav--title'
                  }>
                  {item}
                </p>
              ))}
            </div> */}
          </div>
          <div></div>
          <div className="partneram__info-container">
            <div className="partneram__info-container--top">
              <h2>Партнерам</h2>
              <div className="partneram__info-container--top-description">
                <div className="partneram__info-container--top-description-text">
                  Применение инновационных оконных технологий, четко выстроенная стратегия развития
                  компании и прочные позиции на рынке позволяют оконной компании SCHTERN активно
                  осваивать российский рынок светопрозрачных конструкций. Мы высоко ценим ваше
                  внимание и выражаем вам свою признательность за намерение стать нашим партнером.
                  Наши крупные партнеры получают возможность быстро и эффективно развиваться
                  благодаря активной поддержке. Мы хотим, чтобы и ваша компания открыла для себя
                  новые возможности.
                </div>
                <img src={truck} alt="" />
              </div>
            </div>
            <div className="partneram__info-container--grids">
              <h2>Преимущества работы с SCHTERN</h2>
              <div className="partneram__info-container--grids-1">
                <img src={woman} alt="" style={{ width: '21vw', marginRight: '8vw' }} />
                <div className="partneram__info-container--grids-container">
                  <div className="partneram__info-container--grid">
                    Персональный менеджер. Мы индивидуально подходим к каждому партнеру. Партнер
                    самостоятельно определяет степень и форму нашего участия в том или ином проекте.
                  </div>
                  <div className="partneram__info-container--grid">
                    Доступность и оперативность получения информации. Партнеры первыми получают
                    информацию обо всех изменениях и могут безболезненно оптимизировать свои
                    бизнес-процессы. Отслеживание состояния заказов происходит в on-line режиме.
                  </div>
                  <div className="partneram__info-container--grid">
                    Постоянное расширение ассортимента предлагаемой продукции за счет эксклюзивных
                    продуктов, новейшего оборудования с применением инновационных технологий.
                  </div>
                  <div className="partneram__info-container--grid">
                    Высокая конкурентоспособность предлагаемых решений, их функциональность и
                    технические особенности обеспечивают партнеру неоспоримые преимущества.
                  </div>
                  <div
                    className="partneram__info-container--grid"
                    style={{ display: 'flex', flexDirection: 'column' }}>
                    <img
                      src={clocks}
                      alt=""
                      style={{ width: '7vw', marginLeft: '9vw', marginBottom: '5.5vw' }}
                    />
                    <img
                      src={frame}
                      alt=""
                      style={{
                        width: '4vw',
                        position: 'absolute',
                        top: '9vw',
                        left: '7vw'
                      }}
                    />
                    <img
                      src={frame}
                      alt=""
                      style={{
                        width: '4vw',
                        position: 'absolute',
                        top: '6vw',
                        left: '2vw'
                      }}
                    />
                    Строгое соблюдение сроков изготовления изделий.
                  </div>
                  <div
                    className="partneram__info-container--grid"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-around'
                    }}>
                    Бесплатная доставка на расстояние до 550 км.
                    <img src={bike} alt="" style={{ width: '15vw', marginRight: '0vw' }} />
                  </div>
                  <div className="partneram__info-container--grid">
                    Рекламная поддержка. Мы совместно с партнером планируем и проводим различные
                    маркетинговые кампании и акции. Мы обеспечиваем наших партнеров всеми
                    необходимыми материалами, проводим совместные семинары, участвуем в выставках и
                    конференциях.
                  </div>
                  <div
                    className="partneram__info-container--grid"
                    style={{ flexDirection: 'column', justifyContent: 'space-around' }}>
                    Обучение персонала.
                    <img src={books} alt="" style={{ width: '14vw' }} />
                  </div>
                  <div
                    className="partneram__info-container--grid"
                    style={{ flexDirection: 'column', justifyContent: 'space-around' }}>
                    Поддержка Сервисного центра.
                    <img src={laptop} alt="" style={{ width: '15vw' }} />
                  </div>
                </div>
              </div>
            </div>
            <div className="partneram__info-container--how-to">
              <h2>Стать партнером</h2>
              <div className="partneram__info-container--how-to-steps">
                <div className="partneram__info-container--how-to-step1">
                  <p className="partneram__info-container--how-to-step1_1">1</p>
                  <p className="partneram__info-container--how-to-step1_2">
                    Статус юридического лица или индивидуального предпринимателя
                  </p>
                </div>
                <div className="partneram__info-container--how-to-step2">
                  <p className="partneram__info-container--how-to-step2_1">2</p>
                  <p className="partneram__info-container--how-to-step2_2">
                    Собственные оборотные средства
                  </p>
                </div>
                <div className="partneram__info-container--how-to-step3">
                  <p className="partneram__info-container--how-to-step3_1">3</p>
                  <p className="partneram__info-container--how-to-step3_2">
                    Соблюдение корпоративных стандартов и регламентов SCHTERN
                  </p>
                </div>
                <div className="partneram__info-container--how-to-step4">
                  <p className="partneram__info-container--how-to-step4_1">4</p>
                  <p className="partneram__info-container--how-to-step4_2">Прохождение обучения </p>
                </div>
              </div>
              <div className="partneram__info-container--request">
                <div className="partneram__info-container--request-link">
                  <p style={{ textAlign: 'center', marginTop: '4vw' }}>
                    Заполните заявку на нашем сайте, или позвоните в дилерский отдел SCHTERN по
                    номеру: +7 (4742) 24-29-19. <br />
                  </p>
                  <Link to={'/stat-dillerom'}>
                    <p style={{ textAlign: 'center' }}>Заполнить заявку</p>
                  </Link>
                </div>
                <div className="partneram__info-container--request-benefits">
                  <div className="partneram__info-container--request-benefit-1">
                    Обсудите с менеджером по развитию дилерской сети детали и перспективы
                    сотрудничества, возможности развития бизнеса, которые Вам доступны и выгодны
                  </div>
                  <div className="partneram__info-container--request-benefit-2">
                    Предоставьте менеджеру реквизиты Вашей компании, Вам будет выслан договор с
                    приложениями. Тщательно изучите его и продумайте схему финансирования Вашего
                    проекта
                  </div>
                  <div className="partneram__info-container--request-benefit-3">
                    Посетите завод SCHTERN, чтобы подробно ознакомиться с нашей продукцией
                  </div>
                  <div className="partneram__info-container--request-benefit-4">
                    Заключите договор сотрудничества
                  </div>
                  <div className="partneram__info-container--request-benefit-5">
                    Оборудуйте офис образцами изделий
                  </div>
                  <div className="partneram__info-container--request-benefit-6">
                    Организуйте обучение Вашего персонала
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="mob_partneram__header">
            <div className="mob_partneram__header--img">
              <img src={bg_mob} alt="" />
            </div>
            <div className="mob_partneram__header--menu"></div>
          </div>
          <div className="mob_partneram__header--decription">
            <h2>Партнерам</h2>
            <div className="mob_partneram__header--description-text">
              Применение инновационных оконных технологий, четко выстроенная стратегия развития
              компании и прочные позиции на рынке позволяют оконной компании SCHTERN активно
              осваивать российский рынок светопрозрачных конструкций. Мы высоко ценим ваше внимание
              и выражаем вам свою признательность за намерение стать нашим партнером. Наши крупные
              партнеры получают возможность быстро и эффективно развиваться благодаря активной
              поддержке. Мы хотим, чтобы и ваша компания открыла для себя новые возможности.
            </div>
            <div className="mob_partneram__header--description-img">
              <img src={truck} alt="" />
            </div>
          </div>
          <div className="mob_partneram__header--cards">
            <h2>Преимущества работы с SCHTERN</h2>
            <div className="mob_partneram__header--cards1">
              Персональный менеджер. Мы индивидуально подходим к каждому партнеру. Партнер
              самостоятельно определяет степень и форму нашего участия в том или ином проекте.
            </div>
            <div className="mob_partneram__header--cards2">
              Доступность и оперативность получения информации. Партнеры первыми получают информацию
              обо всех изменениях и могут безболезненно оптимизировать свои бизнес-процессы.
              Отслеживание состояния заказов происходит в on-line режиме.
            </div>
            <div className="mob_partneram__header--cards3">
              Постоянное расширение ассортимента предлагаемой продукции за счет эксклюзивных
              продуктов, новейшего оборудования с применением инновационных технологий.
            </div>
            <div className="mob_partneram__header--cards4">
              {' '}
              Высокая конкурентоспособность предлагаемых решений, их функциональность и технические
              особенности обеспечивают партнеру неоспоримые преимущества.
            </div>
            <div className="mob_partneram__header--cards5">
              {' '}
              <img
                src={clocks}
                alt=""
                style={{ position: 'absolute', top: '7vw', left: '60vw', width: '15vw' }}
              />
              <img
                src={frame}
                alt=""
                style={{ position: 'absolute', top: '8vw', left: '40vw', width: '10vw' }}
              />
              <img
                src={frame}
                alt=""
                style={{ position: 'absolute', top: '12vw', left: '28vw', width: '10vw' }}
              />
              Строгое соблюдение сроков изготовления изделий.
            </div>
            <div className="mob_partneram__header--cards6">
              {' '}
              <img src={bike} alt="" style={{ marginBottom: '5vw', width: '40vw' }} />
              Бесплатная доставка на расстояние до 550 км.
            </div>
            <div className="mob_partneram__header--cards7">
              Рекламная поддержка. Мы совместно с партнером планируем и проводим различные
              маркетинговые кампании и акции. Мы обеспечиваем наших партнеров всеми необходимыми
              материалами, проводим совместные семинары, участвуем в выставках и конференциях.
            </div>
            <div className="mob_partneram__header--cards8">
              <img src={books} alt="" style={{ marginBottom: '5vw', width: '40vw' }} />
              Обучение персонала.
            </div>
            <div className="mob_partneram__header--cards9">
              <img src={laptop} alt="" style={{ marginBottom: '5vw', width: '40vw ' }} />
              Поддержка Сервисного центра.
            </div>
            <div className="mob_partneram__info-container--how-to-steps">
              <div style={{ fontSize: '5vw', color: '#161E54', marginBottom: '5vw' }}>
                Как стать Партнером
              </div>
              <div className="mob_partneram__info-container--how-to-step1">
                <div className="mob_partneram__info-container--how-to-step1_1">1</div>
                <div className="mob_partneram__info-container--how-to-step1_2">
                  Статус юридического лица или индивидуального предпринимателя
                </div>
              </div>
              <div className="mob_partneram__info-container--how-to-step2">
                <div className="mob_partneram__info-container--how-to-step2_1">2</div>
                <div className="mob_partneram__info-container--how-to-step2_2">
                  Собственные оборотные средства
                </div>
              </div>
              <div className="mob_partneram__info-container--how-to-step3">
                <div className="mob_partneram__info-container--how-to-step3_1">3</div>
                <div className="mob_partneram__info-container--how-to-step3_2">
                  Соблюдение корпоративных стандартов и регламентов SCHTERN
                </div>
              </div>
              <div className="mob_partneram__info-container--how-to-step4">
                <div className="mob_partneram__info-container--how-to-step4_1">4</div>
                <div className="mob_partneram__info-container--how-to-step4_2">
                  Прохождение обучения
                </div>
              </div>
            </div>
            <div className="mob_partneram__info-container--request-link">
              <p
                style={{
                  textAlign: 'center',
                  marginTop: '4vw',
                  width: '60vw',
                  marginBottom: '7vw'
                }}>
                Заполните заявку на нашем сайте, или позвоните в дилерский отдел SCHTERN по номеру:
                +7 (4742) 24-29-19. <br />
              </p>
              <Link to={'/stat-dillerom'}>
                <p style={{ textAlign: 'center', fontSize: '4vw' }}>Заполнить заявку</p>
              </Link>
            </div>
            <div className="mob_partneram__info-container--request-benefits">
              <div className="mob_partneram__info-container--request-benefit">
                Обсудите с менеджером по развитию дилерской сети детали и перспективы
                сотрудничества, возможности развития бизнеса, которые Вам доступны и выгодны
              </div>
              <div className="mob_partneram__info-container--request-benefit">
                Предоставьте менеджеру реквизиты Вашей компании, Вам будет выслан договор с
                приложениями. Тщательно изучите его и продумайте схему финансирования Вашего проекта
              </div>
              <div className="mob_partneram__info-container--request-benefit">
                Посетите завод SCHTERN, чтобы подробно ознакомиться с нашей продукцией
              </div>
              <div className="mob_partneram__info-container--request-benefit">
                Заключите договор сотрудничества
              </div>
              <div className="mob_partneram__info-container--request-benefit">
                Оборудуйте офис образцами изделий
              </div>
              <div className="mob_partneram__info-container--request-benefit">
                Организуйте обучение Вашего персонала
              </div>
            </div>
          </div>
        </>
      )}

      <Component13 />
    </>
  );
};
export default Partneram;
